<!--
 * @author: JJ
 * @since: 2024-12-02
 * WarrantyInfo.vue
-->
<template>
    <div>
        <Header></Header>
      <section class="container pc" v-if="detail">
        <img :src="detail.image?detail.image:'../assets/icon/top.png'" class="blog_banner" alt="">
            <div class="info_nav" v-if="info">


              <ul class="info_list " >
                <li class="flex-box fwb" :class="multiAnswer.includes(1) ? 'active' : ''" @mouseenter="chooseItem(1)" @mouseleave="chooseItem(1)">
                  <p class="left">Product Type</p>
                  <p class="right m-ellipsis" v-if="info.order.ProductType==0">Window Film</p>
                  <p class="right m-ellipsis" v-if="info.order.ProductType==1">PPF</p>
                </li>
                <li class="flex-box fwb" :class="multiAnswer.includes(2) ? 'active' : ''" @mouseenter="chooseItem(2)" @mouseleave="chooseItem(2)">
                  <p class="left">E-Warranty ID</p>
                  <p class="right m-ellipsis">{{ info.order.warrantyNo }}</p>
                </li>
                <li class="flex-box fwb" :class="multiAnswer.includes(3) ? 'active' : ''" @mouseenter="chooseItem(3)" @mouseleave="chooseItem(3)">
                  <p class="left">Owner's  Name</p>
                  <p class="right m-ellipsis">{{ info.customer.customer.CustomerName }}</p>
                </li>

                <li class="flex-box fwb" v-if="info.customer.customer.CustomerEmail" :class="multiAnswer.includes(4) ? 'active' : ''" @mouseenter="chooseItem(4)" @mouseleave="chooseItem(4)">
                  <p class="left">Email</p>
                  <p class="right m-ellipsis">{{ info.customer.customer.CustomerEmail }}</p>
                </li>
                <li class="flex-box fwb" v-if="info.customer.customer.VINNO" :class="multiAnswer.includes(5) ? 'active' : ''" @mouseenter="chooseItem(5)"  @mouseleave="chooseItem(5)">
                  <p class="left">Vehicle Identification Number</p>
                  <p class="right m-ellipsis">{{ info.customer.customer.VINNO }}</p>
                </li>
                <li class="flex-box fwb" :class="multiAnswer.includes(6) ? 'active' : ''" @mouseenter="chooseItem(6)" @mouseleave="chooseItem(6)">
                  <p class="left">Car Brand</p>
                  <p class="right m-ellipsis">{{ info.customer.customer.CarBrand }}</p>
                </li>
                <li class="flex-box fwb" :class="multiAnswer.includes(7) ? 'active' : ''" @mouseenter="chooseItem(7)" @mouseleave="chooseItem(7)">
                  <p class="left">Car Model</p>
                  <p class="right m-ellipsis">{{ info.customer.customer.CarModal }}</p>
                </li>

              </ul>

              <view v-for="(item, index) in info.construction">
                <li class="flex-box fwb flex-center mt50 aa" :class="multiAnswer.includes(index+'date') ? 'active' : ''" @mouseenter="chooseItem(index+'date')" @mouseleave="chooseItem(index+'date')">
                  <p class="left">Film Installation Date</p>
                  <p class="right m-ellipsis">{{ item.zorder.ConstructionDate}}</p>
                </li>
                <ul class="info_list mt50" v-for="(item1, index1) in item.zconstruc" >
                  <li class="flex-box fwb" v-if="item1.name" :class="multiAnswer.includes(item1.VehicleId+'0') ? 'active' : ''" @mouseenter="chooseItem(item1.VehicleId+'0')" @mouseleave="chooseItem(item1.VehicleId+'0')">
                    <p class="left">Product Model</p>
                    <p class="right m-ellipsis" >{{item1.name}}</p>

                  </li>
                  <li class="flex-box fwb" v-if="item1.vnames" :class="multiAnswer.includes(item1.VehicleId+'1') ? 'active' : ''" @mouseenter="chooseItem(item1.VehicleId+'1')" @mouseleave="chooseItem(item1.VehicleId+'1')">
                    <p class="left">Installation Area</p>
                    <p class="right m-ellipsis" >{{item1.vnames}}</p>

                  </li>
                  <li class="flex-box fwb" v-if="item1.StockNo" :class="multiAnswer.includes(item1.VehicleId+'2') ? 'active' : ''" @mouseenter="chooseItem(item1.VehicleId+'2')" @mouseleave="chooseItem(item1.VehicleId+'2')">
                    <p class="left">Roll Serial No</p>
                    <p class="right m-ellipsis" >{{item1.StockNo}}</p>

                  </li>
                  <li class="flex-box fwb" v-if="item1.baozhiqi" :class="multiAnswer.includes(item1.VehicleId+'3') ? 'active' : ''" @mouseenter="chooseItem(item1.VehicleId+'3')" @mouseleave="chooseItem(item1.VehicleId+'3')">
                    <p class="left">Warranty Validity Period</p>
                    <p class="right m-ellipsis" >{{item1.baozhiqi}}</p>

                  </li>
                </ul>

              </view>


            </div>
            <div class="info_ad">
                <div class="w1270">
                    <img src="../assets/icon/info.png" alt="">
                </div>
            </div>

        </section>
        <Footer></Footer>
    </div>
</template>

<script setup>
import { onBeforeMount,ref } from "vue"
const infoList = ref([
    { title: 'Product Type', content: 'PPF' },
    { title: 'E-Warranty ID', content: 'UP44039' },
    { title: "Owner's Name", content: 'Lin*' },
    { title: 'Email:', content: 'XXXXXXX@sad.com' },
    { title: 'Plate Number', content: 'BDV4779' },
    { title: 'Vehicle Identification Number', content: '0' },
    { title: 'Car Brand', content: 'BMW' },
    { title: 'Car Model', content: 'X1 2024' },
    { title: 'Product Model', content: 'V7' },
    { title: 'Roll Serial No', content: '6802060771' },
    { title: 'Material Used for Installation', content: '10 m' },
    { title: 'Installation Area', content: 'Full Car' },
    { title: 'Film Installation Date', content: '2023-06-07' },
    { title: 'Warranty Validity Period', content: '2031-06-07' },
    { title: 'Dealer Information', content: 'Nevada Robinson' },
])
let nindex = ref(-1);
let multiAnswer=ref([]);
let detail=ref(null);
const chooseItem = (index) => {
  console.log(index)
  if (multiAnswer.value.includes(index)) {
    multiAnswer.value.pop(index);
  } else {
    multiAnswer.value.push(index);
  }

}
import {apiArticle, apiWarrantyDetail} from "../request/api";
import {useHead} from "@unhead/vue";
import router from "../router";

let info=ref(null);
const params=router.currentRoute.value.query;

const id=ref(0);
if(params.id){
  id.value=params.id;
}
onBeforeMount(() => {
  getInfos();
  getDetail()
})
const getInfos = async () => {
  try {


    const res = await apiArticle({ title: 'Warranty Check' }); //

    if (res.code != 1) {
      return
    }

    detail.value = res.data;


    useHead({
      title: res.data.sub_title,
      meta: [
        {
          name: "description",
          content: res.data.desc,
        },
        {
          name: "keywords",
          content: res.data.keywords,
        },
      ],
    });
  } catch (error) {
    console.error(error);
  }
}

const getDetail=async ()=>{
  const res = await apiWarrantyDetail({ id: id.value }); //

  if (res.code != 1) {
   // router.push('/WarrantyCheck');
    return
  }

  info.value = res.data;
}

</script>
<style lang="less" scoped>
.container {
    background-color: #ECEEEE;
}

.blog_banner {
    width: 100%;
    height: calc(100vw / 1440 * 313);
    object-fit: cover;
}

.info_nav {
    width: 100%;
    padding: 135px 0 210px;
    background-image: url('../assets/icon/warranty_info_bg.png');
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    font-size: 19px;
    color: #152F4A;
.aa{
  cursor: pointer;

            &.active {
                color: #EA5520;
            }
}
    .info_list {
        width: 1180px;
        margin-left: auto;
        margin-right: auto;
        box-shadow: 0px 0px 20px 0px rgba(61, 67, 79, 0.33);
        border: 2px solid #152F4A;
        border-radius: 30px;
        position: relative;
        padding-top: 40px;
        padding-bottom: 40px;

        li+li {
            margin-top: 20px;
        }

        li {
            cursor: pointer;

            &.active {
                color: #EA5520;
            }
        }

    }

    .info_list::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 420px;
        z-index: 1;
        background: #C0C6C8;
        border-radius: 30px;
    }

    .left {
        width: 420px;
        position: relative;
        padding-left: 84px;
        padding-right: 25px;
        z-index: 2;
    }

    .right {
font-weight: normal;
font-family: 'Metropolis-Medium';
        width: 644px;
        line-height: 41px;
        text-align: center;
        border: 1px solid #9A9A9A;
        background: #F4F4F4;
        margin-left: 30px;
        border-radius: 8px;
    }
}

.info_ad {
    border-radius: 8px 8px 0 0;
    position: relative;
    width: 100%;
    background: #fff;
    padding-top: 185px;
    padding-bottom: 220px;

    &::after {
        content: '';
        width: 460px;
        height: 38px;
        background-image: url('../assets/icon/warranty_border.png');
        background-position: 50% 50%;
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
        display: block;
    }

    &::before {
        content: '';
        width: 423px;
        height: 105px;
        background-image: url('../assets/icon/tips.png');
        background-position: 50% 50%;
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        top: -69px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 2;
    }

    img {
        width: 1150px;
        height: auto;
        display: block;
        margin-left: auto;
        margin-right: auto;
        position: relative;
    }
}

@media screen and (max-width: 980px) {
    .info_nav {
        width: 100%;
        padding: 35px 15px 40px;
        font-size: 16px;

        .info_list {
            width: 100%;
            border-radius: 15px;
            padding-top: 20px;
            padding-bottom: 20px;

            li+li {
                margin-top: 10px;
            }

        }

        .info_list::after {

            width: 150px;
            border-radius: 15px;
        }

        .left {
            width: 150px;
            padding-left: 15px;
            padding-right: 15px;
        }

        .right {
            width: 165px;
            line-height: 41px;
            text-align: center;
            margin-left: 15px;
            margin-right: 15px;
            border-radius: 8px;
            padding: 0 10px;
        }
    }

    .info_ad {
        border-radius: 8px 8px 0 0;
        position: relative;
        width: 100%;
        background: #fff;
        padding-top: 90px;
        padding-bottom: 110px;
        margin-top: 30px;

        &::after {
            width: calc(460px * 0.6);
            height: calc(38px * 0.6);
        }

        &::before {
            width: calc(423px * 0.6);
            height: calc(105px * 0.6);
            top: -41px;
        }

        img {
            width: 100%;
        }
    }
}
</style>
