<template>
    <div class="container">
        <Header></Header>
        <section class="pc" >
            <img :src="detail?detail.image:'../assets/icon/top.png'" class="blog_banner" alt="">
            <div class="bg ">
                <div class="w1270 pt40 pr lh18">
                    <div>
                        <div class="before_link"> <router-link to="/">Home /</router-link></div>
                        <div class="before_link"><router-link to="/PPF">Products /</router-link></div>
                        <div class="before_link"><router-link to="/PPF">PPF & Window Film </router-link> </div>
                    </div>
                    <div class="mt70 fs26 semibold col152">Product Information</div>
                    <div class="tab_nav mt50 m-table">
                        <div class="tab_item" @click="changeindex(index)" v-for="(item, index) in tab" :key="index">
                            <div class="content flex-box flex-center">
                                <span class="tag">{{ item.brief }}</span>
                                <p class=" right fwb"><span v-html="item.name"></span></p>
                            </div>
                            <template v-if="item.goods.length > 0">
                                <ul class="list">
                                    <li v-for="(items, indexs) in item.goods" :key="indexs"><router-link :to="{ name: 'ProductInfo', query: { id: items.id } }">{{ items.goods_name}}</router-link></li>
                                </ul>
                            </template>
                        </div>

                    </div>
                    <div class="mt70 flex-box info pb100" v-if="showcategorys">
                        <div class="cover">
                            <img :src="showcategorys.image" alt="">
                        </div>
                        <div class="flex-grow-1 pl70 content">
                            <p class="fwb" v-html="showcategorys.name"></p>
                            <div class="content ">{{showcategorys.desc}}</div>
                        </div>
                    </div>
                </div>

            </div>

        </section>
        <section class="mobile">
            <img :src="detail?detail.image:'../assets/icon/top.png'" class="blog_banner" alt="">
            <div class="bg ">
                <div class="w1270 pt40 pr lh18">
                    <div>
                        <div class="before_link"> <router-link to="/">Home /</router-link></div>
                        <div class="before_link"><router-link to="/PPF">Products /</router-link></div>
                        <div class="before_link"><router-link to="/PPF">PPF & Window Film </router-link> </div>
                    </div>
                    <div class="mt70 fs26 semibold col152">Product Information</div>
                    <div class="tab_nav mt50">
                        <div class="tab_item" @click="changeindex(index)" v-for="(item, index) in tab" :key="index">
                            <div class="content flex-box flex-center">
                                <span class="tag">{{ item.brief }}</span>
                                <p class=" right fwb"><span v-html="item.name"></span></p>
                            </div>
                            <template v-if="item.goods.length > 0">
                                <ul class="list">
                                    <li v-for="(items, indexs) in item.goods" :key="indexs"><router-link :to="{ name: 'ProductInfo', query: { id: items.id } }">{{ items.goods_name}}</router-link></li>
                                </ul>
                            </template>
                        </div>

                    </div>
                  <div class="mt70 info pb50" v-if="showcategorys">
                    <div class="cover">
                      <img :src="showcategorys.image" alt="">
                    </div>
                    <div class="content">
                      <p class="fwb" v-html="showcategorys.name"></p>
                      <div class="content">{{showcategorys.desc}}</div>
                    </div>
                  </div>

                </div>

            </div>

        </section>
        <Footer></Footer>
    </div>
</template>

<script setup>
import { onBeforeMount, ref } from "vue"
import { apiGetPPF } from "../request/api";
import { useHead } from "@unhead/vue";
import { useRouter } from 'vue-router';
const router = useRouter();
import { Navigation, Pagination, Scrollbar, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "../../node_modules/swiper/vue/swiper-vue";
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.min.css";
const modules = [Navigation, Pagination, Scrollbar, Autoplay];
let info = ref('Joyvie PPF features exclusive Multi-Functional\nTopCoat technology providing self-heading.stain,\nand corrosion resistance,extending the films lifespan\nand maintaining a glossy appearance.')
let nindex = ref(-1)
const tab = ref([])
const chooseItem = (i) => {
    if (features.value.includes(i)) {
        features.value.pop(i)
    } else {
        features.value.push(i)
    }
}
const detail = ref(null);
const features = ref([]);
onBeforeMount(() => {
    getInfos()
})
const params = router.currentRoute.value.query;


const showcategorys=ref(null);

const getInfos = async () => {
    try {


        const res = await apiGetPPF({  }); // 调用接口
        if (res.code != 1) {
            return
        }

        detail.value = res.data;
        if(res.data.categorys){
          if(res.data.categorys.length>0){
            showcategorys.value=res.data.categorys[0];
          }
          tab.value=res.data.categorys;
        }


        useHead({
            title: res.data.sub_title,
            meta: [
                {
                    name: "description",
                    content: res.data.desc,
                },
                {
                    name: "keywords",
                    content: res.data.keywords,
                },
            ],
        });
    } catch (error) {
        console.error(error);
    }
}

const changeindex=async (index)=>{
  showcategorys.value=tab.value[index];
}



</script>
<style lang="less" scoped>
.pc{display: block;}
.mobile{display: none;}
.container {
    background-color: #ECEEEE;
}

.blog_banner {
    width: 100%;
    height: calc(100vw / 1440 * 313);
    object-fit: cover;
}

.before_link {

    a {
        color: #152F4A;
    }
}
.before_link:hover a{
  color: #FB471F;
}
.w1100 {
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;
}

.bg {
    width: 100%;
    background-image: url('../assets/icon/bg.png');
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: repeat-y;
}



.tab_nav {
    padding-bottom: 100px;
    .tab_item {
        display: inline-block;
        vertical-align: top;
        margin-right: 10px;
        width: 175px;
        background: #C0C6C8;
        border-radius: 36px;
        cursor: pointer;
        position: relative;

        &:nth-of-type(6n) {
            margin-right: 0;
        }

        .list {
            display: none;
        }

        .content {
            width: 175px;
            height: 73px;
            position: relative;
            z-index: 2;

            img {

                width: 31px;
                height: 31px;
                object-fit: cover;
                display: block;
            }

            .right {
                margin-left: 10px;
                padding-left: 10px;
                border-left: 1px solid #152F4A;
                height: 29px;
                line-height: 17px;
                color: #152F4A;
            }
        }

        &:hover {
            background: #FFFFFF;

        }

        &:hover .list {
            display: block;
            padding-left: 35px;
            padding-right: 20px;
            padding-bottom: 25px;
            padding-top: 5px;
            position: absolute;
            top: 73px;
            left: 0;
            right: 0;
            background: #FFFFFF;
            border-radius: 0 0 36px 36px;
        }

        &:hover .list::before {
            content: '';
            background: #FFFFFF;
            top: -36px;
            width: 100%;
            left: 0;
            height: 36px;
            position: absolute;
            z-index: 1;
        }

        li {

            position: relative;

            a {
                display: inline-block;
                border-bottom: 1px solid #152F4A;
                line-height: 22px;
                padding-right: 20px;
                vertical-align: middle;
                cursor: pointer;
            }
        }

        li:before {
            content: '';
            display: inline-block;
            vertical-align: middle;
            margin-right: 10px;
            width: 3px;
            height: 3px;
            background: #152F4A;
            border-radius: 2px;

        }

        .tag {
            width: 31px;
            display: block;
            line-height: 31px;
            text-align: center;
            font-size: 25px;
            color: #FFFFFF;
            background: #152F4A;
            border-radius: 50%;
            font-family: 'Metropolis-SemiBold';
        }
    }
}

.cover {
    width: 504px;
    height: 582px;
    border-radius: 23px;
    overflow: hidden;

    img {
        width: 100%;
        height: 100%;
        display: block;
        border-radius: 23px;
        object-fit: cover;
        transition: all 0.6s;
    }

    img:hover {
        transform: scale(1.4);
    }
}

.info {
    p {
        font-size: 82px;
        color: #152F4A;
        line-height: 90px;
    }

    .content {
        margin-top: 75px;
        font-weight: 400;
        font-size: 22px;
        color: #152F4A;
        line-height: 40px;
    }
}

.pb100 {
    padding-bottom: 145px;
}

@media screen and (max-width: 1280px) {
    .tab_nav {
  
  .tab_item {
      display: inline-block;
      vertical-align: top;
      margin-right: 8px;
      width: 150px;
      background: #C0C6C8;
      border-radius: 36px;
      cursor: pointer;
      position: relative;

      &:nth-of-type(6n) {
          margin-right: 0;
      }

      .list {
          display: none;
      }

      .content {
          width: 150px;
          height: 73px;
          position: relative;
          z-index: 2;

          img {

              width: 31px;
              height: 31px;
              object-fit: cover;
              display: block;
          }

          .right {
              margin-left: 10px;
              padding-left: 10px;
              border-left: 1px solid #152F4A;
              height: 29px;
              line-height: 17px;
              color: #152F4A;
          }
      }

      &:hover {
          background: #FFFFFF;

      }

      &:hover .list {
          display: block;
          padding-left: 35px;
          padding-right: 20px;
          padding-bottom: 25px;
          padding-top: 5px;
          position: absolute;
          top: 73px;
          left: 0;
          right: 0;
          background: #FFFFFF;
          border-radius: 0 0 36px 36px;
      }

      &:hover .list::before {
          content: '';
          background: #FFFFFF;
          top: -36px;
          width: 100%;
          left: 0;
          height: 36px;
          position: absolute;
          z-index: 1;
      }

      li {

          position: relative;

          a {
              display: inline-block;
              border-bottom: 1px solid #152F4A;
              line-height: 22px;
              padding-right: 20px;
              vertical-align: middle;
              cursor: pointer;
          }
      }

      li:before {
          content: '';
          display: inline-block;
          vertical-align: middle;
          margin-right: 10px;
          width: 3px;
          height: 3px;
          background: #152F4A;
          border-radius: 2px;

      }

      .tag {
          width: 31px;
          display: block;
          line-height: 31px;
          text-align: center;
          font-size: 25px;
          color: #FFFFFF;
          background: #152F4A;
          border-radius: 50%;
          font-family: 'Metropolis-SemiBold';
      }
  }
}
}


@media screen and (max-width: 980px) {
    .pt40{padding-top: 20px;}
    .mt70{margin-top: 45px;}
    .mt50{margin-top: 25px;}
    .pc{display: none;}
.mobile{display: block;}
::-webkit-scrollbar{display: none;}
    .tab_nav {
  white-space: nowrap;
  overflow-x: auto;
  position: relative;
  overflow-y: visible;
  height: 180px;

  .tab_item {
      display: inline-block;
      vertical-align: top;
      margin-right: 8px;
      width: 150px;
      background: #C0C6C8;
      border-radius: 36px;
      cursor: pointer;
      position: relative;

      &:nth-of-type(6n) {
          margin-right: 0;
      }

      .list {
          display: none;
      }

      .content {
          width: 150px;
          height: 73px;
          position: relative;
          z-index: 2;

          img {

              width: 31px;
              height: 31px;
              object-fit: cover;
              display: block;
          }

          .right {
              margin-left: 10px;
              padding-left: 10px;
              border-left: 1px solid #152F4A;
              height: 29px;
              line-height: 17px;
              color: #152F4A;
          }
      }

      &:hover {
          background: #FFFFFF;

      }

      &:hover .list {
          display: block;
          padding-left: 35px;
          padding-right: 20px;
          padding-bottom: 25px;
          padding-top: 5px;
          position: absolute;
          top: 73px;
          left: 0;
          right: 0;
          background: #FFFFFF;
          border-radius: 0 0 36px 36px;
      }

      &:hover .list::before {
          content: '';
          background: #FFFFFF;
          top: -36px;
          width: 100%;
          left: 0;
          height: 36px;
          position: absolute;
          z-index: 1;
      }
    
  }
}

.cover {
    width: 100%;
    height: calc(100% / 504 * 582);
}
.info {
    p {
        font-size: 41px;
        color: #152F4A;
        line-height: 45px;
    }

    .content {
        margin-top: 30px;
        font-size: 14px;
        line-height: 20px;
    }
}
.video_link {
       
        width: 100%;
      
        padding-top: 40px;
        padding-bottom: 50px;
        margin-top: 30px;

        &::after {
            width: calc(460px * 0.6);
            height: calc(38px * 0.6);
        }

        &::before {
            width: calc(423px * 0.6);
            height: calc(105px * 0.6);
            top: -41px;
        }

        img {
            width: 100%;
        }
        .video_item {
        width: 100%;
        display: block;
        margin-right: 0;
        margin-top: 30px;
        font-size: 12px;

        &:nth-of-type(2n) {
            margin-right: 0;
        }

        &:nth-of-type(-n+2) {
            margin-top: 30px;
        }

    }

    .video_nav {
        width: 100%;
        height: calc(1280 / 532 * 277);
        display: block;
    }
    }
    .product_nav {

    padding-top: 65px;
    padding-bottom: 75px;

    i {
        width: calc(554px * 0.6);
        height: calc(57px * 0.6);
    }

    .info_nav {
        width: 100%;
        margin-top: 30px;
        padding: 15px;

        .item {
            height: 100px;
            width:calc(50% - 5px);
            
         
margin-right: 10px;
&:nth-of-type(2n){margin-right: 0;}
            &::before {
                content: '';
                width: 100%;
                height: 1px;
                border-bottom: 1px dashed #DFDDDB;
                border-top: none;
                display: block;
                position: absolute;
                bottom: 0;
                left: 0;
            }

            &::after {
               
                display: none;
            }

            p {
                font-size: 11px;
              
            }

            img {
              
                width: 25px;
                height: 28px;
               
            }
        }

    }
}
.bottom_img{margin-top: -50px;}
}
</style>

