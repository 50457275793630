<!--
 * @author: JJ
 * @since: 2024-12-02
 * WarrantySearch.vue
-->
<template>
  <div>
    <Header></Header>
    <section class="container pc" v-if="detail">
      <img :src="detail.image ? detail.image : '../assets/icon/top.png'" class="blog_banner" alt="">
      <div class="search_link">
        <div class="w1270 flex-box flex-center">
          <input type="text" v-model="keyword" class="inp_nav medium"
            placeholder="Input your warranty ID / email / Plate No.">
          <button class="btn_search medium" @click="search">search</button>
         
        </div>
        <div class="w1270 tc  mt120 fs22 lh48 medium col0" v-if="warrant_years">
            <p>As of {{ nowTime }}, </p>
            <p><span class="fwb fs50 coleb5 pr10">{{warrant_years}}</span>world-wide clients </p>
            <p>have chosen JOYVIE products.</p>
        </div>
      </div>
    </section>
    <section class="container mobile" v-if="detail">
      <img :src="detail.image ? detail.image : '../assets/icon/top.png'" class="blog_banner" alt="">
      <div class="search_link">
        <div class="w1270">
          <input type="text" v-model="keyword" class="inp_nav medium"
            placeholder="Input your warranty ID / tel / email / Vehicle Identification No. / Plate No.">
          <button class="btn_search medium" @click="search">search</button>
          <div class=" mt50 fs12 lh26 tc medium col0" v-if="warrant_years">
            <p>As of {{ nowTime }}, </p>
            <p><span class="fwb fs30 coleb5 pr10">{{warrant_years}}</span>world-wide clients </p>
            <p>have chosen JOYVIE products.</p>
          </div>
        </div>
      </div>
    </section>
    <Footer></Footer>
  </div>
</template>

<script setup>
import { onBeforeMount, ref,onMounted,onBeforeUnmount } from "vue"
import { apiArticle, apiCheckWarranty,apigetWarrantys } from "../request/api";
import { useHead } from "@unhead/vue";
import router from "../router";
const keyword = ref('');

const detail = ref(null);

const warrant_years=ref(null);
const getWarrants=async ()=>{
  try {

    const res = await  apigetWarrantys(); // 调用接口

    if(res.code==1){
      warrant_years.value=res.data.replace(/\B(?=(\d{3})+(?!\d))/g,",");
    }





  } catch (error) {
    console.error(error);
  }
}
const getInfos = async () => {
  try {


    const res = await apiArticle({ title: 'Warranty Check' }); //

    if (res.code != 1) {
      return
    }

    detail.value = res.data;


    useHead({
      title: res.data.sub_title,
      meta: [
        {
          name: "description",
          content: res.data.desc,
        },
        {
          name: "keywords",
          content: res.data.keywords,
        },
      ],
    });
  } catch (error) {
    console.error(error);
  }
}
const search = async () => {

  if (!keyword.value || keyword.value == '') {
    alert('Input your warranty ID / tel / email / Vehicle Identification No. / Plate No.')
  }
  try {


    const res = await apiCheckWarranty({ filter: keyword.value }); //

    if (res.code != 1) {
      alert(res.msg);
      return
    }

    router.push({ name: 'Warrantylist', query: { filter: keyword.value } })
  } catch (error) {
    console.error(error);
  }
}
/**
 * 显示实时时间
 */

 const nowTime = ref('')
/**
 * 将小于10的数字前面补0
 * @function
 * @param {number} value
 * @returns {string} 返回补0后的字符串
 */
const complement = (value) => {
  return value < 10 ? `0${value}` : value.toString()
}
/**
 * 格式化时间为XXXX年XX月XX日XX时XX分XX秒
 * @function
 * @returns {string} 返回格式化后的时间
 */
const formateDate = () => {
  const time = new Date()
  const year = time.getFullYear()
  const month = complement(time.getMonth() + 1)
  const day = complement(time.getDate())
  return `${year}-${month}-${day}`
}
let timer = 0
/**
 * 设置定时器
 */
 onMounted(() => {

   timer = setInterval(() => {
     nowTime.value = formateDate()
   }, 1000)
   getWarrants()
})
onBeforeMount(() => {
  getInfos()
})
onBeforeUnmount(() => {
  clearInterval(timer) //清除定时器
  timer = 0
})
</script>
<style lang="less" scoped>
.pc {
  display: block;
}

.mobile {
  display: none;
}

.blog_banner {
  width: 100%;
  height: calc(100vw / 1440 * 313);
  object-fit: cover;
}

.container {
  background-color: #eeeeec;
}

.search_link {
  width: 100%;
  background-position: 50% 50%;
  background-size: cover;
  background-image: url('../assets/icon/warranty_bg.png');
  background-repeat: no-repeat;
  padding-top: 110px;
  padding-bottom: 260px;

  .inp_nav {
    width: 425px;
    height: 57px;
    background: #FFFFFF;
    border-radius: 18px;
    padding-left: 30px;
    padding-right: 30px;
    font-size: 14px;
  }

  .btn_search {
    width: 158px;
    line-height: 57px;
    text-align: center;
    background: #EA5520;
    border-radius: 18px;
    font-size: 21px;
    color: #FFFFFF;
    text-align: center;
    margin-left: 20px;
    cursor: pointer;
  }
}

input::placeholder {
  color: #C0C6C8;
}

@media screen and (max-width: 980px) {
  .pc {
    display: none;
  }

  .mobile {
    display: block;
  }

  .search_link {

    padding-top: 55px;
    padding-bottom: 130px;

    .inp_nav {
      width: 100%;
      height: 57px;
      border-radius: 18px;
      padding-left: 15px;
      padding-right: 15px;
      font-size: 14px;
    }

    .btn_search {
      width: 158px;
      line-height: 48px;
      border-radius: 16px;
      font-size: 16px;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      margin-top: 30px;
      display: block;
    }
  }
}
.mt120 {
  margin-top: 120px;
}

.coleb5 {
  color: #EB5525;
}
.mt120{margin-top: 120px;}
</style>
