<!--
 * @author: JJ
 * @since: 2024-11-08
 * PlotterInfo.vue
-->
<template>
    <div class="container" v-if="detail">
        <Header />
        <section>
            <img :src="detail.image" class="blog_banner" alt="">
            <img src="../assets/icon/watermark.png" class="watermark mr0" alt="">
            <div class="w1270 mt_10 pr  lh18">
                <div>
                    <div class="before_link"> <router-link  to="/" >Home</router-link></div>
                  <div class="before_link"><router-link to="/PPF">Products /</router-link></div>
                    <div class="before_link">  <router-link to="/Plotter">Plotter  </router-link> </div>
<!--                    <div class="now_link">{{detail.goods_name}}</div>-->
                </div>
                <div class="pt65 fwb fs40 col1 lh40">{{detail.goods_name}}</div>
                <div class="pc">
                    <div class="pt60 flex-box video_link pb80">
                        <div class="video_nav">
                            <video
                                :src="detail.video"
                                @play="onPlay"
                                @pause="onPause"
                                :controls="controls" ref="myVideo1"></video>
                            <img src="../assets/icon/play.png" v-if="!controls" @click="operateVideo" class="play"
                                alt="">
                        </div>
                        <div class="flex-grow-1 pl50">
                            <p class="fs30">{{detail.goods_name}}</p>
                            <span class="fs16" v-html="detail.content">

                            </span>
                        </div>
                    </div>
                </div>
                <div class="mobile">
                    <div class="pt30 video_link pb40">
                        <div class="video_nav">
                            <video
                                :src="detail.video"
                                @play="onPlay"
                                @pause="onPause"
                                :controls="controls" ref="myVideo"></video>
                            <img src="../assets/icon/play.png" v-if="!controls" @click="operateVideo1" class="play"
                                alt="">
                        </div>
                        <div class="mt30">
                            <p class="fs30">{{detail.goods_name}}</p>
                            <span class="fs16" v-html="detail.content">

                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pc">
                <div class="gard_nav">
                    <div class="flex-box pr80">
                        <div class="title_nav flex-grow-1 flex-box fwb fs40 col1">
                            <img src="../assets/icon/icon_x.png" alt="">
                            <div>Key Features</div>
                        </div>
                        <a class="download_btn" v-if="detail.pdf_url" :href="detail.pdf_url" target="_blank">Download PDF</a>
                    </div>
                    <ul class="w1270 pt70">
                        <li class="gard_item col1" v-for="(item, index) in detail.features" :key="index">
                            <span class="dot1" v-if="index == 4"></span>
                            <span class="dot2" v-if="index == 4"></span>
                            <template v-if="item.type==1">
                                <p class="fs24 fwb lh24">{{item.title}}</p>
                                <div class="mt30 fs16 lh24 m-ellipsis-l5">{{item.content}}</div>
                            </template>
                            <template v-else>
                                <p class="img_nav">
                                    <img :src="item.image" alt="">
                                </p>
                            </template>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="mobile">
                <div class="gard_nav ">
                    <div class="title_nav flex-box fwb fs40 col1">
                        <img src="../assets/icon/icon_x.png" alt="">
                        <div>Key Features</div>
                    </div>
                    <a class="download_btn" v-if="detail.pdf_url" :href="detail.pdf_url">Download PDF</a>
                    <ul class="w1270 pt70">
                        <li class="gard_item col1" v-for="(item, index) in detail.features" :key="index">
                            <span class="dot1" v-if="index == 4"></span>
                            <span class="dot2" v-if="index == 4"></span>
                            <template v-if="item.type==1">
                                <p class="fs24 fwb lh24">{{item.title}}</p>
                                <div class="mt30 fs16 lh24 m-ellipsis-l5">{{item.content}}</div>
                            </template>
                            <template v-else>
                                <p class="img_nav">
                                    <img :src="item.image" alt="">
                                </p>
                            </template>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="pt80 pb70">
                <div class="title_nav flex-box fwb fs40 col1 pb20">
                    <img src="../assets/icon/icon_x.png" alt="">
                    <div>Related pictures</div>
                </div>
                <ul class="w1270" id="pic">
                    <li class="picture_li" v-for="(item, index) in detail.images_arr" :key="index">
                        <img :src="item" alt="">
                    </li>
                </ul>
            </div>
        </section>

        <Footer />
    </div>
</template>

<script setup>
import {onBeforeMount, ref} from "vue"
import {apiGetPlotterDetail} from "../request/api";
import {useHead} from "@unhead/vue";
import { useRouter } from 'vue-router';
const router = useRouter();
let controls = ref(false)
let myVideo1 = ref(null)
let myVideo = ref(null)

const detail=ref(null);
const features=ref([]);
onBeforeMount(()=>{
 /// getInfos()
})
const params=router.currentRoute.value.query;

console.log(params)
const id=ref(0);
if(params.id){
  id.value=params.id;
}

const getInfos = async () => {
  try {


    const res = await apiGetPlotterDetail({id:id.value}); // 调用接口
    if(res.code!=1){
      return
    }

    detail.value=res.data;


    useHead({
      title: res.data.sub_title,
      meta: [
        {
          name: "description",
          content: res.data.desc,
        },
        {
          name: "keywords",
          content: res.data.keywords,
        },
      ],
    });
  } catch (error) {
    console.error(error);
  }
}
const operateVideo = () => {
  if(myVideo1.value){

    if (controls.value) {
      //
      myVideo1.value.pause();
      controls.value =false;
    } else {
      myVideo1.value.play();

      controls.value = true;
    }
  }

}
const operateVideo1 = () => {
  if(myVideo.value){

    if (controls.value) {
      //
      myVideo.value.pause();
      controls.value =false;
    } else {
      myVideo.value.play();

      controls.value = true;
    }
  }

}
const onPlay = () => {
  controls.value = true;
  console.log('Video is playing');
};

const onPause = () => {
  controls.value = false;
  console.log('Video is paused');
};
</script>
<style lang="less" scoped>
.pc {
    height: auto;
    overflow: auto;
}

.mobile {
    height: 0;
    overflow: hidden;
}

.blog_banner {
    width: 100%;
    height: calc(100vw / 1440 * 400);
    object-fit: cover;
}

.watermark {
    width: 704px;
    height: auto;
    display: block;
}

.mt_10 {
    margin-top: -10px;
}

.before_link {
    color: #898989;
    display: inline-block;
}

.now_link {
    color: #555;
    display: inline-block;
}

.video_link {
    p {
        color: #101010;
        line-height: 30px;
    }

    span {
        margin-top: 30px;
        color: #101010;
        line-height: 26px;
        display: block;
    }

    .video_nav {
        position: relative;
        width: 725px;
        height: 450px;
        border-radius: 10px;

        video {
            display: block;
            width: 100%;
            height: 100%;
            position: relative;
            z-index: 1;
            border-radius: 10px;
        }

        .play {
            width: 65px;
            height: 45px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            cursor: pointer;
            z-index: 2;
        }
    }
}

.gard_nav {
    width: 100%;
    padding: 70px 0 80px;
    background: #F3F7FF;

    .download_btn {
        width: 150px;
        height: 45px;
      display: block;
        background: #FB471F;
        border-radius: 12px;
        line-height: 45px;
        text-align: center;
        font-size: 14px;
        color: #FFFFFF;
    }

    .gard_item {
        width: 33.3%;
        height: 290px;
        position: relative;
        display: inline-flex;
        align-items: center;
        vertical-align: top;
        flex-direction: column;
        padding-top: 50px;
        text-align: center;

        .img_nav {
            width: 350px;
            height: 190px;
            overflow: hidden;
            border-radius: 7px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: block;
            border-radius: 7px;
            transition: all 0.6s;
        }

        img:hover {
            transform: scale(1.4);
        }

        div {
            max-width: 350px;
        }
    }
}

.gard_item+.gard_item::before {
    content: '';
    width: 1px;
    height: 190px;
    background: #CCCCCC;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
}

.gard_item::after {
    content: '';
    width: 340px;
    height: 1px;
    background: #CCCCCC;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
}

.gard_item:nth-of-type(4):before {
    display: none;
}

.gard_item:nth-of-type(7):before {
    display: none;
}

.gard_item:nth-of-type(-n+3):after {
    display: none;
}

.gard_item:nth-of-type(-n+3) {
    padding-top: 0;
    height: 241px;
}

.gard_item:nth-of-type(7) {
    height: 241px;
}

.gard_item:nth-of-type(8) {
    height: 241px;
}

.gard_item:nth-of-type(9) {
    height: 241px;
}

.gard_item .dot1 {
    height: 0;
}

.gard_item .dot2 {
    height: 0;
}

.gard_item .dot1::before {
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 50%;
    position: absolute;
    top: -4px;
    left: -4px;
    background: #0A2944;
}



.gard_item .dot1::after {
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 50%;
    position: absolute;
    top: -4px;
    right: -4px;
    background: #0A2944;
}

.gard_item .dot2::before {
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 50%;
    position: absolute;
    bottom: -4px;
    left: -4px;
    background: #0A2944;
}

.gard_item .dot2::after {
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 50%;
    position: absolute;
    bottom: -4px;
    right: -4px;
    background: #0A2944;
}

.pr80 {
    padding-right: 80px;
}

.title_nav {
    img {
        width: 115px;
        height: auto;
        margin-right: 20px;
    }
}

.picture_li {
    display: inline-block;
    vertical-align: top;
    margin-top: 40px;
    width: 400px;
    margin-right: 35px;
    border-radius: 7px;
    overflow: hidden;
    img {
        width: 100%;
        height: 243px;
        display: block;
        object-fit: cover;
        border-radius: 7px;
        transition: all 0.6s;
    }
    img:hover{
        transform: scale(1.4);
    }
}

.picture_li:nth-of-type(3n) {
    margin-right: 0;
}

@media screen and (max-width: 1280px) {
    .video_link {
        .video_nav {
            width: calc(725px * 0.6);
            height: calc(450px * 0.6);

        }
    }

    .gard_nav {
        width: 100%;
        padding: 70px 0 30px;
        background: #F3F7FF;

        .download_btn {
            width: 150px;
            height: 45px;
            background: #FB471F;
            border-radius: 12px;
            line-height: 45px;
            text-align: center;
            font-size: 14px;
            color: #FFFFFF;
        }

        .gard_item {
            width: 33.3%;
            height: 290px;
            position: relative;
            display: inline-flex;
            align-items: center;
            vertical-align: top;
            flex-direction: column;
            padding-top: 50px;
            text-align: center;

            .img_nav {
                width: calc(350px * 0.7);
                height: calc(190px * 0.7);
                overflow: hidden;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                display: block;
                border-radius: 7px;
            }

            div {
                max-width: calc(350px * 0.7);
            }
        }
    }

    .picture_li {
        display: inline-block;
        vertical-align: top;
        margin-top: 40px;
        width: 400px;
        margin-right: 35px;

        img {
            width: 100%;
            height: 243px;
            display: block;
            object-fit: cover;
        }
    }

    .picture_li:nth-of-type(3n) {
        margin-right: 35px;
    }

    .picture_li:nth-of-type(2n) {
        margin-right: 0px;
    }

    #pic.w1270 {
        max-width: 835px;
    }
}

@media screen and (max-width: 980px) {
    .watermark {
        width: calc(100% / 1440 * 704);
        height: auto;
        display: block;
    }

    .mobile {
        height: auto;
        overflow: auto;
    }

    .pc {
        height: 0;
        overflow: hidden;
    }

    .video_link {
        .video_nav {
            width: 100%;
            height: calc(100% * 725 / 450);
            border-radius: 10px;
        }
    }

    .gard_nav {
        width: 100%;
        padding: 35px 0 15px;
        background: #F3F7FF;

        .download_btn {
            width: 150px;
            height: 45px;
            background: #FB471F;
            border-radius: 12px;
            line-height: 45px;
            text-align: center;
            font-size: 14px;
            color: #FFFFFF;
            margin-top: 15px;
            margin-left: auto;
            margin-right: 15px;
        }

        .gard_item {
            width: 100%;
            height: 290px;
            position: relative;
            display: inline-flex;
            align-items: center;
            vertical-align: top;
            flex-direction: column;
            padding-top: 50px;
            text-align: center;

            .img_nav {
                width: 100%;
                height: 100%;
                overflow: hidden;
                border-radius: 7px;
            }

            img {
                width: 100%;
                height: 190px;
                object-fit: cover;
                display: block;
                border-radius: 7px;
            }

            div {
                max-width: 100%;
            }
        }

        .gard_item::before {
            display: none;
        }

        .gard_item:nth-of-type(-n+3):after {
            display: block;
        }

        .gard_item:first-child:after {
            display: none;
        }

        .gard_item .dot1 {
            display: none;
        }

        .gard_item .dot2 {
            display: none;
        }
    }

    .picture_li {
        display: block;
        margin-top: 40px;
        width: 100%;
        margin-right: 0;

        img {
            width: 100%;
            height: 243px;
            display: block;
            object-fit: cover;
        }
    }

    .picture_li:nth-of-type(3n) {
        margin-right: 0;
    }

    .picture_li:nth-of-type(2n) {
        margin-right: 0px;
    }
}
</style>
