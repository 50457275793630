<!--
 * @author: JJ
 * @since: 2024-11-08
 * Plotter.vue
-->
<template>
  <div class="container" v-if="detail">
    <Header />
    <section>
      <swiper ref="swiper" @swiper="onSwiper" @slideChange="onSlideChange" :pagination="{ clickable: true }"
        :autoplay="{ delay: 3000, disableOnInteraction: false }" :modules="modules" class="blog_swiper product_s" loop>
        <swiper-slide v-for="(items, index) in detail.images_arr">
          <img :src="items" class="blog_banner" alt="">
        </swiper-slide>

      </swiper>
      <img src="../assets/icon/watermark.png" class="watermark mr0" alt="">
      <div class="w1270 mt_10 pr fs14 lh18">
        <div class="before_link"> <router-link to="/">Home /</router-link></div>
        <div class="before_link"><router-link to="/PPF">Products /</router-link></div>
        <div class="now_link"> <router-link to="/Plotter">Plotter </router-link> </div>

      </div>
      <div class="w1270 pt65 pb80">
        <p class="fwb fs40 col1 lh40 pb10">Plotter</p>
        <ul class="flex flex-start flex-wrap flex-between">
          <li class="plotter_item col1 tc" v-for="(items, index) in detail.label_arr" :key="index">
            <img :src="items.image" alt="" class="animate__animated">
            <p class="fw500 fs24 lh24">{{ items.title }}</p>
            <div class="fs16 lh20">{{ items.content }}</div>
          </li>

        </ul>
      </div>
      <div class="model_nav">
        <div class="title_nav flex-box fwb fs40 col1">
          <img src="../assets/icon/icon_x.png" alt="">
          <div>Comparison of {{ detail.count }} Plotter Models</div>
        </div>
        <ul class="w1058">
          <li class="model_item" v-for="(item, index) in detail.goods" :key="index">
            <div class="img_nav">
              <img :src="item.goods_image" alt="">
            </div>
            <div class="model_info">
              <div class="flex-box col152">
                <p class="flex-grow-1 fs36 fwb m-ellipsis pr10">{{ item.goods_name }}</p>
                <img :src="item.image" class="ico" alt="">
              </div>
              <p class="mt20 fwb col152">PERFORMANCE</p>
              <div class="m-table">

                <div class="item flex-col fs12" v-for="(item1, index1) in item.labels" :key="index1">
                  <p class="m-ellipsis col0 fs17">{{item1.content}}</p>
                  <p class="tr col1526">{{item1.name}}</p>
                </div>

              </div>
              <a :href="item.pdf_url" target="_blank" class="download_nav tc fw500">
                <span>Download Datasheet</span>
              </a>
            </div>
          </li>
        </ul>
      </div>
      <!-- <img :src="detail.mini_image ? detail.mini_image : '../assets/icon/plotter_bottom.png'" class="bottom_banner" -->
      <!-- alt=""> -->
      <img src="../assets/icon/plotter_bottom.png" class="bottom_banner" alt="">
    </section>
    <Footer />
  </div>
</template>

<script setup>
import { onBeforeMount, ref } from "vue"
import { apiArticle } from "../request/api";
import { useHead } from "@unhead/vue";
import { useRouter } from 'vue-router';
import { Navigation, Pagination, Scrollbar, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "../../node_modules/swiper/vue/swiper-vue";
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.min.css";
import 'swiper/css/effect-creative';
const modules = [Navigation, Pagination, Scrollbar, Autoplay];
const router = useRouter()
const a = ref(0)
const detail = ref(null);
onBeforeMount(() => {
  getInfos()
})


const getInfos = async () => {
  try {

    const res = await apiArticle({ title: 'Plotter' }); // 调用接口

    detail.value = res.data;


    useHead({
      title: res.data.sub_title,
      meta: [
        {
          name: "description",
          content: res.data.desc,
        },
        {
          name: "keywords",
          content: res.data.keywords,
        },
      ],
    });
  } catch (error) {
    console.error(error);
  }
}
</script>
<style lang="less" scoped>
.col1526 {
  color: rgba(21, 47, 74, .6);
}

.pc {
  height: auto;
  overflow: auto;
}

.mobile {
  height: 0;
  overflow: hidden;
}

.blog_swiper {
  width: 100%;
  height: calc(100vw / 1440 * 400);
}

.blog_banner {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

.watermark {
  width: 704px;
  height: auto;
  display: block;
}

.mt_10 {
  margin-top: -10px;
}

.before_link {
  color: #898989;
  display: inline-block;
}

.now_link {
  color: #555;
  display: inline-block;
}

.plotter_item {
  width: 392px;
  margin-top: 60px;

  &:hover img {
    -webkit-animation-name: bounce;
    animation-name: bounce;
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
  }

  img {
    width: 74px;
    height: auto;
  }

  p {
    margin-top: 30px;
    font-family: Metropolis-Medium;
  }

  div {
    margin-top: 15px;
  }
}

.model_nav {
  width: 100%;
  background: #F3F7FF;
  padding-top: 70px;
  padding-bottom: 80px;

  .title_nav {
    padding-bottom: 30px;

    img {
      width: 115px;
      height: auto;
      margin-right: 20px;
    }
  }

  .w1058 {
    width: 1058px;
    margin-left: auto;
    margin-right: auto;
  }

  .model_item {
    display: inline-block;
    vertical-align: top;
    margin-top: 30px;
    width: 514px;
    border-radius: 23px;
    border: 2px solid #152F4A;
    margin-right: 25px;
    transition: all 0.6s;

    .download_nav {
      display: block;
      width: 100%;
      line-height: 26px;
      background: #FB471F;
      border-radius: 9px;
      margin-top: 25px;
      color: #FFFFFF;

      span {
        position: relative;
        text-align: center;

        &::after {
          content: '';
          width: 13px;
          height: 14px;
          background-image: url('../assets/icon/icon_down.png');
          background-position: 50% 50%;
          display: inline-block;
          background-repeat: no-repeat;
          background-size: cover;
          vertical-align: middle;
          margin-left: 4px;
        }
      }
    }

    .img_nav {
      width: 100%;
      height: 265px;
      border-radius: 23px 23px 0px 0px;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .model_info {
      width: 100%;
      background: #FFFFFF;
      border-radius: 23px;
      padding: 30px 50px;

      .ico {
        width: auto;
        height: 42px;
      }

      .item {
        height: 60px;
        border-bottom: 1px dashed #152F4A;
        width: 180px;
        display: inline-flex;
        align-items: normal;
        justify-content: end;
        padding-bottom: 5px;
        margin-right: 50px;

        p:first-child {
          font-family: "Metropolis-ExtraBold";
        }
      }

      .item:nth-of-type(2n) {
        margin-right: 0;
      }
    }
  }

  .model_item:hover {
    transform: translateY(-10px);
    box-shadow: 0 1px 6px 0 rgba(184, 189, 202, 1);
  }

  .model_item:nth-of-type(2n) {
    margin-right: 0;
  }

  .more {
    width: 121px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    font-size: 14px;
    color: #FFFFFF;
    background: #FB471F;
    border-radius: 8px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    display: block;
  }

  .more:hover {
    font-size: 16px;
  }
}

.plr29 {
  padding-left: 29px;
  padding-right: 29px;
}

.w132 {
  min-width: 132px;
}

.bottom_banner {
  width: 100%;
  height: auto;
  display: block;
}

@media screen and (max-width: 1280px) {}

@media screen and (max-width: 980px) {
  .mobile {
    height: auto;
    overflow: hidden;
  }

  .pc {
    height: 0;
    overflow: auto;
  }

  .watermark {
    width: calc(100% / 1440 * 704);
    height: auto;
    display: block;
  }

  .mt_10 {
    margin-top: 10px;
  }

  .plotter_item {
    width: 100%;
    margin-top: 60px;
  }

  .model_nav {
    .w1058 {
      width: 100%;
      padding-left: 15px;
      padding-right: 15px;
    }

    .model_item {
      margin-right: auto;
      margin-left: auto;
      display: block;
      width: 100%;
      .model_info {
        .ico {
          width: auto;
          height: 26px;
        }

        .item {
          width: 100%;
          margin-right: 0;
        }
      }
    }

    .model_item:nth-of-type(2n) {
      margin-right: auto;
    }
  }
}
</style>
