<!--
 * @author: JJ
 * @since: 2024-12-24
 * TrainingInfo.vue
-->
<template>
    <div class="container" v-if="detail">
        <Header />
        <section class="main">
            <div class="blog_banner flex-box flex-center">
                <img :src="detail.images" class="title" alt="">
            </div>
            <div class="bg">
                <div class="w1270 pt40 pr pb80">
                    <div class="lh18">
                        <div class="before_link"> <router-link to="/">Home /</router-link></div>
                        <div class="before_link"><router-link to="/WarrantyCheck">Technical Center /</router-link></div>
                        <div class="before_link"><router-link to="/Training">Training</router-link> </div>
                    </div>
                    <div class="mt70 fs26 semibold col152">Training</div>
                    <section class="pc">
                        <div class="flex-box mt80 col152">
                        <div class="flex-grow-1 pr80">
                            <h1 class="h1 fwb" v-html="detail.name"></h1>
                            <div class="fs17 mt20">{{detail.content}}</div>
                        </div>
                        <img :src="detail.detail_image" class="banner1" alt="">
                    </div>
                    </section>
                   <section class="mobile">
                    <div class="mt40 col152">
                       
                            <h1 class="h1 fwb" v-html="detail.name"></h1>
                            <div class="fs17 mt20">{{detail.content}}</div>
                       
                        <img :src="detail.detail_image" class="banner1 mt30" alt="">
                    </div>
                   </section>
                    <ul class="mt80 col152 fs25 medium">
                        <li class="adv_item" v-for="(item, index) in detail.steps" :key="index">
                            <span class="number fwb fs26">{{index+1}}</span><span>{{item.content}}</span>
                        </li>
                    </ul>
                </div>
                <div class="number_link">
                    <div class="w1440 h250 flex-box">
                        <div class="flex-grow-1 tc">
                            <p class="big fweb">{{detail.cost_price}}$</p>
                            <p class="fs34">Cost</p>
                        </div>
                        <div class="flex-grow-1 tc">
                            <p class="big fweb">{{detail.cost_days}}</p>
                            <p class="fs34">Days</p>
                        </div>
                        <div class="flex-grow-1 tc">
                            <p class="big fweb">{{detail.cost_people}}</p>
                            <p class="fs34">People</p>
                        </div>
                    </div>
                </div>
                <div class="swiper_link">
                    <div class="w1270">
                        <div class="flex-box">
                            <div class="flex-grow-1 pr80">
                                <h1 class="fs56 fwb col152">MEET<br />OUR TRAINERS</h1>
                                <div class="fs17 lh22 col152 mt35">{{detail.train_content}}</div>
                            </div>
                            <swiper ref="swiper" @swiper="onSwiper" @slideChange="onSlideChange" :modules="modules"
                                class="training_swiper" :observer="true" :observeParents="true"
                                :slides-per-view="isPc ? 2 : 1.2">
                              <swiper-slide v-for="(items, indexs) in detail.trainers" :key="indexs" class="training_swiper_item m-shadow"
                                            :class="[activeIndex == indexs ? 'active' : '']">
                                  <img :src="items.image" class="cover" alt="">
                                    <div class="ptb10 plr30 col152">
                                      <p class="fs22 fwb">{{items.name}}</p>
                                      <div class="mt10 fs12 m-ellipsis-l9 h6">{{items.desc}}</div>
                                      <div  class="train_btn" @click="showpops(indexs)">View Profile</div>
                                    </div>
                                </swiper-slide>
                            </swiper>
                        </div>
                        <div class="flex-box mt20" style="display: flex;">
                            <img src="../assets/icon/arrow_left.png" @click="prev" class="arrow_left" alt="">
                            <img src="../assets/icon/arrow_right.png" @click="next" class="arrow_right" alt="">
                        </div>
                        <router-link to="/ContactUs" class="jump fs25 fwb colf">BOOK NOW</router-link>
                    </div>
                </div>



                <div class="popup-mask" :class="showpop ? 'popup-mask_visible' : ''" @click="showpop = false"></div>

              <div class="popup-wrap" :class="showpop ? 'popup-wrap_visible' : ''">
                <div class="popup">
                  <img src="../assets/icon/new_close.png" class="close" alt="" @click="showpop = false" >
                  <div class="flex flex-align-end" v-if="trainers">
                    <img :src="trainers.big_image" class="sy" alt="">
                    <div class="right col152">
                      <p class="fweb fs45">{{trainers.name}}</p>
                      <p class="mt60 fs20 medium">SPECIALIZATION</p>
                      <p class="fs15">{{trainers.special}}</p>
                      <p class="sq"></p>
                      <div v-html="trainers.content"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </section>
        <Footer />
    </div>
</template>

<script setup>
import {onBeforeMount, onMounted, ref, watch} from "vue"

import { useRouter } from 'vue-router';
const router = useRouter();
const params=router.currentRoute.value.query;

const id=ref(0);
if(params.id){
  id.value=params.id;
}
import { Navigation, Pagination, Scrollbar, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "../../node_modules/swiper/vue/swiper-vue";
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.min.css";
import {apiGetTrains} from "../request/api";
import {useHead} from "@unhead/vue";
const modules = [Navigation, Pagination, Scrollbar, Autoplay];
let swi = ref(null)
let activeIndex = ref(0)
let showpop = ref(false)
const onSwiper = (e) => {
    swi.value = e
    console.log(swi.value);

}
const onSlideChange = (swiper) => {
    activeIndex.value = swiper.realIndex
};
const prev = () => {
    swi.value.slidePrev();
}
const next = () => {
    swi.value.slideNext()
}
const screenWidth = ref(window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth)
let isPc = ref(true)
onMounted(() => {
    if (screenWidth.value > 980) {
        isPc.value = true;
    } else {
        isPc.value = false;
    }
    window.onresize = () => {
        return (() => {
            screenWidth.value = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

            if (screenWidth.value > 980) {
                isPc.value = true;
            } else {
                isPc.value = false;
            }
            console.log(isPc.value);

        })()
    }
  getInfos()

})
const  trainers=ref([]);
const showpops=(index)=>{

  trainers.value=detail.value.trainers[index];
  console.log(detail.value);
  showpop.value=true;
}
watch(() => screenWidth, (val) => {
    screenWidth.value = val

})
const detail = ref(null);
onBeforeMount(() => {
 //getInfos()
  console.log(1111)
})
const getInfos = async () => {
  try {


    const res = await apiGetTrains({ id:id.value }); // 调用接口
    // if(res.code!=1||!res.data){
    //   return
    // }

    detail.value = res.data;



    useHead({
      title: res.data.title,
      meta: [
        {
          name: "description",
          content: res.data.desc,
        },
        {
          name: "keywords",
          content: res.data.keywords,
        },
      ],
    });
  } catch (error) {
    console.error(error);
  }
}
</script>
<style lang="less" scoped>
.container {
    background-color: #ECEEEE;
}

.blog_banner {
    width: 100%;
    height: calc(100vw / 1440 * 313);
    background-image: url('../assets/icon/training_banner.png');
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;

    .title {
        width: 45.8%;
        height: auto;
        display: block;

    }
}

.before_link {

    a {
        color: #152F4A;
    }
}

.before_link:hover a {
    color: #FB471F;
}

.pc {
    height: auto;
    overflow: auto;
}

.mobile {
    height: 0;
    overflow: hidden;
}

.h1 {
    font-size: 74px;
    line-height: 80px;
}

.pr80 {
    padding-right: 80px;
}

.banner1 {
    width: 518px;
    height: 345px;
    border-radius: 15px;
    object-fit: cover;
}

.adv_item {
    width: 50%;
    display: inline-block;
    vertical-align: top;
    margin-top: 15px;
    line-height: 43px;

    .number {
        border: 3px solid #EB5525;
        width: 43px;
        height: 43px;
        line-height: 37px;
        text-align: center;
        display: inline-block;
        vertical-align: top;
        border-radius: 15px;
        margin-right: 15px;
    }
}

.number_link {
    width: 100%;
    position: relative;
    z-index: 2;

    .h250 {
        height: 250px;
    }

    .w1440 {
        position: relative;
        z-index: 2;
    }

    &::after {
        content: '';
        display: block;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: 100%;
        height: 250px;
        background-image: url('../assets/icon/ra.png');
        background-repeat: repeat-y;
        background-position: 50% 50%;
        background-size: cover;
        position: absolute;
    }

    .big {
        font-size: 76px;
        line-height: 76px;
        color: #EB5525;
    }
}

.swiper_link {
    width: 100%;
    position: relative;
    padding-top: 150px;
    padding-bottom: 150px;

    &::before {
        content: '';
        display: block;
        width: 800px;
        height: 450px;
        background-image: url('../assets/icon/sq.png');
        background-position: 50% 50%;
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        right: 0;
        top: -225px;
        z-index: 0;
    }
}

.popup-wrap {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 100;
    transition: all 0.4s ease;
    opacity: 1;
    overflow: hidden;
    width: 0;
    transform: none;
}

.popup-wrap.popup-wrap_visible {
    width: calc(100% - 30px);
    transition: all 0.4s ease;
    transform: none;
    height: calc(100vh - 90px);
    margin: 75px 15px 15px;
}

.popup-mask {
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;
    position: fixed;
}

.popup {
    max-width: 1440px;
    height: calc(100%);
    background: #fff;
    position: relative;
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 80px;
    margin-left: auto;
    margin-right: auto;
    overflow-y: auto;

    .close {
        width: 16px;
        height: 16px;
        position: absolute;
        top: 18px;
        right: 18px;
    }

    .sy {
        width: 516px;
        height: 510px;
        border-radius: 33px;
        box-shadow: 3px 3px 7px 1px rgba(0, 0, 0, .3);
        object-fit: cover;
        display: block;
        position: relative;
        margin-right: -210px;
    }

    .right {
        width: 1055px;
        height: 666px;
        display: block;
        background: #EFECEB;
        border-radius: 108px 0 0 108px;
        padding-left: 255px;
        padding-right: 70px;
        padding-top: 100px;

        .sq {
            margin-top: 30px;
            margin-bottom: 30px;
            width: 100%;
            height: 1px;
            background: #EB5525;
        }

     /deep/   ul {
            width: 100%;
            max-height: 260px;
            overflow-y: hidden;
        }

    /deep/    li {
            &::before {
                content: '';
                width: 3px;
                height: 3px;
                border-radius: 50%;
                margin-top: 8px;
                margin-right: 8px;
                background: #152F4A;
                display: inline-block;
                vertical-align: top;
            }

            margin-top: 5px;
        }
    }
}

.training_swiper {
    width: 525px;
    height: 450px;

    &_item {
        width: 250px !important;
        height: 432px;
        margin-right: 25px;
        background: #fff;
        transform: scale(0.9);
        cursor: pointer;
    }

    &_item.active {
        transform: scale(1);
    }

    .cover {
        width: 100%;
        height: 205px;
        object-fit: cover;
    }

    .train_btn {
        font-size: 15px;
        color: #fff;
        border-radius: 12px;
        width: 170px;
        line-height: 24px;
        background: #EB5525;
        display: block;
        margin: 10px auto 0;
        text-align: center;
    }

    .h6 {
        line-height: 13px;
        height: 117px;
    }
}

.arrow_left {
    width: 22px;
    cursor: pointer;
}

.arrow_right {
    width: 50px;
    margin-left: 15px;
    cursor: pointer;
}
.jump{
    display: block;
    margin: 50px auto 0;
    width: 190px;
    line-height: 56px;
    text-align: center;
    border-radius: 30px;
    background: #1C334A;
}
@media screen and (max-width: 1280px) {
    .bg2 {
        width: 100%;
        height: calc(100vw / 1440 * 380 * 1.5);
        background-image: url('../assets/icon/training_bg1.png');
        background-position: 50% 50%;
        background-size: cover;
        background-repeat: no-repeat;
        background-color: transparent;
    }

    .fs56 {
        font-size: 52px;
    }

    .training_mid {
        width: 100%;
        height: 169px;

        img {
            width: 350px;
            height: 169px;
            object-fit: cover;
            display: block;
            border-radius: 80px;
        }
    }

    .train_ul {
        padding-bottom: 60px;

        .train_item {
            width: 290px;
            height: 440px;
            border-radius: 17px;
            background: #fff;
            box-shadow: 3px 3px 7px 1px rgba(0, 0, 0, .3);
            position: relative;
            margin-right: 30px;
            margin-top: 45px;
            display: inline-block;

            &:nth-of-type(4n) {
                margin-right: 30px;
            }

            &:nth-of-type(3n) {
                margin-right: 0px;
            }





        }
    }

    .popup {

        .sy {
            width: calc(516px * 0.9);
            height: calc(510px * 0.9);
            border-radius: 33px;
            box-shadow: 3px 3px 7px 1px rgba(0, 0, 0, .3);
            object-fit: cover;
            display: block;
            position: relative;
            margin-right: -210px;
        }


    }
}

@media screen and (max-width: 980px) {
    .mobile {
        height: auto;
        overflow: hidden;
    }

    .pc {
        height: 0;
        overflow: auto;
    }
.h1 {
    font-size: 56px;
    line-height: 62px;
}
    .video_nav {
        position: relative;
        width: calc(395px * 0.8);
        height: calc(470px * 0.8);
        margin-left: auto;
        margin-right: auto;
        border-radius: 23px;

        video {
            display: block;
            width: 100%;
            height: 100%;
            position: relative;
            z-index: 1;
            border-radius: 23px;
        }

        .play {
            width: 72px;
            height: 72px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            cursor: pointer;
            z-index: 2;
        }
    }

    .title1 {
        width: 90%;
        display: block;
    }

    .bg2 {
        width: 100%;
        min-height: calc(100vw / 1440 * 380);
        height: auto;
        background-image: url('../assets/icon/training_bg1.png');
        background-position: 50% 50%;
        background-size: cover;
        background-repeat: no-repeat;
        background-color: transparent;
        padding-bottom: 40px;
    }

    .pt130 {
        padding-top: 60px;
    }

    .mid_title {
        height: 40px;
        line-height: 20px;
        font-size: 20px;
        display: table-cell;
        vertical-align: bottom;
    }

    .swiper_link {
        .flex-box {
            display: block;
        }

        .flex-grow-1 {
            width: 100%;
        }
    }

    .swiper_link {
        width: 100%;
        position: relative;
        padding-top: 80px;
        padding-bottom: 30px;
        &::before {
            content: '';
            display: block;
            width: calc(800px * 0.4);
            height: calc(450px * 0.4);
            background-image: url('../assets/icon/sq.png');
            background-position: 50% 50%;
            background-size: cover;
            background-repeat: no-repeat;
            position: absolute;
            right: 0;
            top: -90px;
            z-index: 0;
        }
    }

    .training_swiper {
        width: 100%;
        height: 450px;
        margin-top: 30px;

        &_item {
            width: 250px !important;
            height: 432px;
            margin-right: 15px;
            background: #fff;
            transform: scale(0.9);
            cursor: pointer;
        }

        &_item.active {
            transform: scale(1);
        }

        .cover {
            width: 100%;
            height: 205px;
            object-fit: cover;
        }

        .train_btn {
            font-size: 15px;
            color: #fff;
            border-radius: 12px;
            width: 170px;
            line-height: 24px;
            background: #EB5525;
            display: block;
            margin: 10px auto 0;
            text-align: center;
        }

        .h6 {
            line-height: 13px;
            height: 117px;
        }
    }

    .image_show {
        margin-top: -38px;
        white-space: nowrap;
        position: relative;
        z-index: 3;

        img {
            width: 110px;
            height: 76px;
            border-radius: 10px;
            margin-right: 10px;
            object-fit: cover;
        }
    }

    .evi_link {

        width: 100%;

        padding-top: 40px;
        padding-bottom: 50px;
        margin-top: 30px;

        &::after {
            width: calc(460px * 0.6);
            height: calc(38px * 0.6);
        }

        &::before {
            width: calc(423px * 0.6);
            height: calc(105px * 0.6);
            top: -41px;
        }

        img {
            width: 100%;
        }
    }

    .training_mid {
        width: 100%;
        height: 113px;
        margin-top: 35px;

        img {
            width: 234px;
            height: 113px;
            object-fit: cover;
            display: block;
            border-radius: 53px;
        }
    }

    .training_mid_div {
        padding-bottom: 38px;

        &::after {
            content: '';
            display: block;
            width: calc(650px * 0.4);
            height: calc(190px * 0.4);
            background-image: url('../assets/icon/sq_l.png');
            background-position: 50% 50%;
            background-size: cover;
            background-repeat: no-repeat;
            position: absolute;
            left: 0;
            bottom: 0;
            z-index: 0;
        }
    }
    .banner1 {
    width: 100%;
    display: block;
    height:calc(100% / 518 * 345);
    border-radius: 15px;
    object-fit: cover;
}
.pb80{padding-bottom: 40px;}
.fs25{font-size: 20px;}
.adv_item {
    width: 100%;
    display: block;
    margin-top: 15px;
    line-height: 43px;

    .number {
        border: 3px solid #EB5525;
        width: 43px;
        height: 43px;
        line-height: 37px;
        text-align: center;
        display: inline-block;
        vertical-align: top;
        border-radius: 15px;
        margin-right: 15px;
    }
}

.number_link {
    width: 100%;
    position: relative;
    z-index: 2;

    .h250 {
        height: 250px;
    }

    .w1440 {
        position: relative;
        z-index: 2;
    }

    &::after {
        content: '';
        display: block;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: 100%;
        height: 250px;
        background-image: url('../assets/icon/ra.png');
        background-repeat: repeat-y;
        background-position: 50% 50%;
        background-size: cover;
        position: absolute;
    }

    .big {
        font-size: 40px;
        line-height: 40px;
        color: #EB5525;
    }
}
}

@media screen and (max-width: 760px) {
    .train_ul {
        padding-bottom: 60px;

        &::before {
            content: '';
            display: block;
            width: calc(540px * 0.4);
            height: calc(330px * 0.4);
            background-image: url('../assets/icon/mini.png');
            background-position: 50% 50%;
            background-size: cover;
            background-repeat: no-repeat;
            position: absolute;
            right: 0;
            bottom: 60px;
            z-index: 0;
        }

        .train_item {
            width: 290px;
            height: 440px;
            border-radius: 17px;
            background: #fff;
            box-shadow: 3px 3px 7px 1px rgba(0, 0, 0, .3);
            position: relative;
            margin-right: auto;
            margin-left: auto;
            margin-top: 45px;
            display: block;

            &:nth-of-type(4n) {
                margin-right: auto;
            }

            &:nth-of-type(3n) {
                margin-right: auto;
            }
        }
    }

    .popup {
        max-width: 1440px;
        height: calc(100%);
        background: #fff;
        position: relative;
        padding-top: 25px;
        padding-bottom: 25px;
        padding-left: 20px;
        margin-left: auto;
        margin-right: auto;
        overflow-y: auto;

        .close {
            width: 16px;
            height: 16px;
            position: absolute;
            top: 18px;
            right: 18px;
        }

        .sy {
            width: calc(516px * 0.9);
            height: calc(510px * 0.9);
            border-radius: 33px;
            box-shadow: 3px 3px 7px 1px rgba(0, 0, 0, .3);
            object-fit: cover;
            display: block;
            position: relative;
            margin-right: -210px;
            display: none;
        }

        .right {
            width: 100%;
            height: 666px;
            display: block;
            background: #EFECEB;
            border-radius: 108px 0 0 108px;
            padding-left: 25px;
            padding-right: 25px;
            padding-top: 100px;

            .sq {
                margin-top: 30px;
                margin-bottom: 30px;
                width: 100%;
                height: 1px;
                background: #EB5525;
            }

            ul {
                max-height: 400px;
            }
        }
    }
}
</style>
