<!--
 * @author: JJ
 * @since: 2024-11-07
 * AboutUs.vue
-->
<template>
    <div class="container">
        <Header></Header>
        <section class="pc">
            <img :src="image" v-if="image" class="blog_banner" alt="">
            <div class="w1270 pt30 pb70">
                <div class="pr z2 lh18">
                   <div class="before_link"> <router-link  to="/" >Home /</router-link></div>
                  <div class="before_link"><router-link  to="/blog" >About Joyvie /</router-link></div>
                    <div class="now_link"> <router-link to="/aboutUs">About Us</router-link>  </div>
                </div>
                <ul class="mt65">
                  <template  v-for="(item, index) in List" :key="index">

                    <li :class="index % 2==0 ? 'item_left':'item_right'">
                      <div class="flex flex-start fwb col1 pr z2">
                        <div class="flex-grow-1 fs50 lh72 pr30"><span v-html="item.title"></span></div>
                        <div class="fs80 lh100">{{item.sub_name}}</div>
                      </div>
                      <div class="flex-box mt70 fs16 col1 lh26 pr z2">
                        <div class="cover_nav">
                          <img :src="item.image" alt="">
                        </div>
                        <div class="flex-grow-1 pl50" v-html="item.content"></div>
                      </div>
                    </li>
                  </template>
                </ul>
                <img src="../assets/icon/n2.png" class="rich_img" alt="">
            </div>
        </section>
        <section class="mobile">
            <img :src="image" v-if="image" class="blog_banner" alt="">
            <div class="w1270 pt30 pb70">
                <div class="pr z2 lh18">
                  <div class="before_link"> <router-link  to="/" >Home /</router-link></div>
                  <div class="before_link"><router-link  to="/blog" >About Joyvie /</router-link></div>
                  <div class="now_link"> <router-link to="/aboutUs">About Us</router-link>  </div>
                </div>
                <ul class="mt65">
                  <template  v-for="(item, index) in List" :key="index">
                    <li :class="index % 2==0 ? 'item_left':'item_right'">
                        <div class="flex flex-start fwb col1 pr z2">
                            <div class="flex-grow-1 fs60 lh72 pr30"><span v-html="item.title"></span></div>
                            <div class="fs80 lh100">{{item.sub_name}}</div>
                        </div>
                        <div class=" mt70 fs16 col1 lh26 pr z2">
                            <div class="cover_nav">
                                <img :src="item.image" alt="">
                            </div>
                            <div class="mt15"  v-html="item.content"></div>
                        </div>
                    </li>
                  </template>
                </ul>
                <img src="../assets/icon/n2.png" class="rich_img" alt="">
            </div>
        </section>
        <Footer></Footer>
    </div>
</template>

<script setup>
import {onBeforeMount, onMounted, ref} from "vue"
import {apiArticle,apiGetAboutUs} from "../request/api";
import {useHead} from "@unhead/vue";



let image=ref('');
let List=ref('');
onMounted(() => {
  getAbouts()
});
const getAbouts = async () => {
  try {

    const res = await apiGetAboutUs(); // 调用接口


   if(res.data){
     List.value=res.data
   }

  } catch (error) {
    console.error(error);
  }
}
onBeforeMount(()=>{
  getInfos()
})

const getInfos = async () => {
  try {

    const res = await apiArticle({title:'About US'}); // 调用接口

      if(res.data.image){
        image.value=res.data.image;
      }

    useHead({
      title: res.data.sub_title,
      meta: [
        {
          name: "description",
          content: res.data.desc,
        },
        {
          name: "keywords",
          content: res.data.keywords,
        },
      ],
    });
  } catch (error) {
    console.error(error);
  }
}
</script>
<style lang="less" scoped>
.container{background-color: #F3F7FF;}
.mobile{height: 0;overflow: hidden;}
.blog_banner {
    width: 100%;
    height: calc(100vw / 1440 * 400);
    object-fit: cover;
}

.before_link {
    color: #898989;
    display: inline-block;
}

.now_link {
    color: #555;
    display: inline-block;
}

.mt65 {
    margin-top: 65px;
}

li+li {
    margin-top: 110px;
}

li {
    .cover_nav {
        width: 750px;
        height: 450px;
        border-radius: 10px;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            display: block;
            object-fit: cover;
            transition: all 0.6s;

            &:hover {
                transform: scale(1.4);
            }
        }
    }
}
.pr50{padding-right: 50px;}
.item_left{position: relative;}
.item_left::after{
  background-image: url('../assets/icon/about_bg.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  content: '';
  width: 100vw;
  height: 130%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}
@media screen and (max-width: 1280px) {
    li {
        .cover_nav {
            width: calc(750px * 0.6);
            height: calc(450px * 0.6);
            border-radius: 10px;
            overflow: hidden;
        }
    }
}
@media screen and (max-width: 980px) {
    .fs60{font-size: 25px;}
    .fs80{font-size: 30px;}
    .lh72{line-height: 30px;}
    .lh100{line-height: 30px;}
    .pl50{padding-left: 20px;}
    .pr50{padding-right: 20px;}
    .pc{height: 0;overflow: hidden;}
    .mobile{height: auto;overflow: auto;}
    .mt65 {
    margin-top: 30px;
}
.mt70{margin-top: 35px;}
    li {
        .cover_nav {
            width: calc(750px * 0.4);
            height: calc(450px * 0.4);
            border-radius: 10px;
            overflow: hidden;
            margin-left: auto;
            margin-right: auto;
        }
    }
}
.rich_img{
  display: block;
  width: 100%;
  height: auto;
  margin-top: 30px;
}
</style>
