<!--
 * @author: JJ
 * @since: 2024-12-04
 * SafeIn.vue
-->
<template>
    <div>
        <Header></Header>
        <section class="main">
            <img src="../assets/icon/banner1.png" class="blog_banner" alt="">
            <div class="process_nav">
                <div class="w1270">
                    <p class="col152 fs30 semibold">Installation Process</p>
                    <img src="../assets/icon/process_img.png" class="process_img" alt="">
                </div>
            </div>
            <div class="sop_link pc">
                <div class="w1270">
                    <p class="semibold fs30 tc">We implement a three-step inspection process</p>
                    <div class="flex-box flex-center mt90">
                        <div class="item">
                            <div class="top">
                                <img src="../assets/icon/safe0.png" alt="">
                            </div>
                            <div class="bottom fs20 semibold">The installer<br>self-check.</div>
                        </div>
                        <div class="item  alt">
                            <div class="top">
                                <img src="../assets/icon/safe1.png" alt="">
                            </div>
                            <div class="bottom fs20 semibold">The technical supervisor conducts a 2nd review.</div>
                        </div>
                        <div class="item ">
                            <div class="top">
                                <img src="../assets/icon/safe2.png" alt="">
                            </div>
                            <div class="bottom fs20 semibold">The store manager performs the final inspection.</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="sop_link mobile">
                <div class="w1270">
                    <p class="semibold fs30 tc">We implement a three-step inspection process</p>
                    <div class=" mt90">
                        <div class="item">
                            <div class="top">
                                <img src="../assets/icon/safe0.png" alt="">
                            </div>
                            <div class="bottom fs20 semibold">The installer<br>self-check.</div>
                        </div>
                        <div class="item alt">
                            <div class="top">
                                <img src="../assets/icon/safe1.png" alt="">
                            </div>
                            <div class="bottom fs20 semibold">The technical supervisor conducts a 2nd review.</div>
                        </div>
                        <div class="item">
                            <div class="top">
                                <img src="../assets/icon/safe2.png" alt="">
                            </div>
                            <div class="bottom fs20 semibold">The store manager performs the final inspection.</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="w1270">
                <div class="title_nav tr">
                    <img src="../assets/icon/title1.png" class="title_img" alt="">
                    <p>Hairline Marks on the Film Surface: These can <br> disappear after heating or exposure to
                        sunlight.</p>
                </div>
                <div>
                    <div class="item1">
                        <img src="../assets/icon/icon1.png" class="icon" alt="">
                        <p class="m-ellipsis fwb">Dust & Stain</p>
                        <span>The diameter of dust particles should not exceed 1mm. Dust particles should not be visible
                            within a 1-meter distance from the film surface. There should be no more than five visible
                            dust particles on the entire film surface.</span>
                    </div>
                    <div class="item1">
                        <img src="../assets/icon/icon2.png" class="icon" alt="">
                        <p class="m-ellipsis fwb">Bubbles</p>
                        <span>The diameter of water bubbles should not exceed 5mm. If it exceeds 6mm, it needs to be
                            addressed. No visible air bubbles should appear in the lines or grooves.</span>
                    </div>
                    <div class="item1">
                        <img src="../assets/icon/icon3.png" class="icon" alt="">
                        <p class="m-ellipsis fwb">Crease & Scrach</p>
                        <span>Creases caused by improper installation method or scratches caused by improper
                            squeegees.</span>
                    </div>
                    <div class="item1">
                        <img src="../assets/icon/icon4.png" class="icon" alt="">
                        <p class="m-ellipsis fwb">Glue Mark</p>
                        <span>Glue residue caused by repositioning the installed film due to wrong positioning during
                            installation.</span>
                    </div>
                    <div class="item1">
                        <img src="../assets/icon/icon5.png" class="icon" alt="">
                        <p class="m-ellipsis fwb">Wrapping</p>
                        <span>No visible wrinkles or lifted edges. Wrinkle length should not exceed 2mm.</span>
                    </div>
                    <div class="item1">
                        <img src="../assets/icon/car.png" class="car" alt="">
                    </div>
                </div>
            </div>

        </section>
        <Footer></Footer>
    </div>
</template>

<script setup>
import { ref } from "vue"
import {useHead} from "@unhead/vue";
useHead({
  title: 'Safe-IN Details_JOYVIE',
  meta: [
    {
      name: "description",
      content: 'Safe-IN Details_JOYVIE',
    },
    {
      name: "keywords",
      content: 'Safe-IN Details_JOYVIE',
    },
  ],
});
</script>
<style lang="less" scoped>
.pc {
    display: block;
}

.mobile {
    display: none;
}

.main {
    background-color: #ECEEEE;
    padding-bottom: 100px;
}

.blog_banner {
    width: 100%;
    height: calc(100vw / 1440 * 313);
    object-fit: cover;
    display: block;
}

.process_nav {
    width: 100%;
    background-image: url('../assets/icon/bg1.png');
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: repeat-y;
    padding-top: 80px;
    padding-bottom: 80px;

    .process_img {
        width: 991px;
        height: auto;
        margin-top: 30px;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}

.mt90 {
    margin-top: 90px;
}

.sop_link {
    border-radius: 8px 8px 0 0;
    position: relative;
    width: 100%;
    background: #152F4A;
    padding-top: 118px;
    padding-bottom: 100px;
    margin-top: 80px;

    &::after {
        content: '';
        width: 470px;
        height: 38px;
        background-image: url('../assets/icon/border2.png');
        background-position: 50% 50%;
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        top: 0px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
        display: block;
    }

    &::before {
        content: '';
        width: 424px;
        height: 106px;
        background-image: url('../assets/icon/sop_btn.png');
        background-position: 50% 50%;
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        top: -69px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 2;
    }

    p {
        color: #fff;
    }

}

.item {
    width: 269px;
    background: #BFC5C7;
    border-radius: 30px;
    margin-right: 65px;
    line-height: 30px;

    .top {
        background: #FFFFFF;
        border-radius: 30px 30px 0px 0px;
        padding-top: 6px;

        img {
            display: block;
            width: 116px;
            height: 116px;
            object-fit: contain;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .bottom {
        height: 161px;
        padding: 30px;
        color: #152F4A;
    }

    &.alt {
        background: #E43D10;

        .top {
            background: #BFC5C7;
        }

        .bottom {
            color: #FFFFFF;
        }
    }

    &:nth-of-type(3n) {
        margin-right: 0;
    }
}

.title_nav {
    width: 962px;
    padding-top: 25px;
    margin-left: auto;
    margin-right: auto;

    .title_img {
        width: 962px;
        height: auto;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    p {
        margin-top: 50px;
        line-height: 26px;
        color: #666666;
    }
}

.item1 {
    display: inline-block;
    vertical-align: top;
    width: calc((100% - 80px) / 3);
    margin-right: 40px;
    margin-top: 45px;

    &:nth-of-type(3n) {
        margin-right: 0;
    }

    .icon {
        display: block;
        width: 51px;
        height: auto;
    }

    p {
        margin-top: 30px;
        color: #1C1C1C;
    }

    span {
        display: block;
        margin-top: 25px;
        color: #666666;
        line-height: 26px;
    }

    .car {
        width: 100%;
        height: auto;
    }
}

@media screen and (max-width: 980px) {
    .pc {
        display: none;
    }

    .mobile {
        display: block;
    }

    .main {
        padding-bottom: 30px;
    }

    .process_nav {
        width: 100%;
        background-image: url('../assets/icon/bg1.png');
        background-position: 50% 50%;
        background-size: cover;
        background-repeat: repeat-y;
        padding-top: 40px;
        padding-bottom: 40px;

        .process_img {
            width: 100%;
            height: auto;
            margin-top: 30px;
            display: block;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .item {
        margin-right: auto;
        margin-left: auto;
        margin-top: 30px;

        &:nth-of-type(3n) {
            margin-right: auto;
        }
    }

    .mt90 {
        margin-top: 0;
    }

    .sop_link {
        width: 100%;
        padding-top: 40px;
        padding-bottom: 50px;
        margin-top: 30px;

        &::after {
            width: calc(470px * 0.6);
            height: calc(38px * 0.6);
            top: -0.5px;
        }

        &::before {
            width: calc(424px * 0.6);
            height: calc(106px * 0.6);
            top: -41px;
        }



    }

    .title_nav {
        width: 100%;
        margin-left: auto;
        margin-right: auto;

        .title_img {
            width: 100%;
            height: auto;
            display: block;
            margin-left: auto;
            margin-right: auto;
        }

        p {
            margin-top: 25px;
            line-height: 26px;
            color: #666666;
        }
    }

    .item1 {
        display: inline-block;
        vertical-align: top;
        width: calc(50% - 5px);
        margin-right: 10px;
        margin-top: 45px;

        &:nth-of-type(3n) {
            margin-right: 10px;
        }
        &:nth-of-type(2n) {
            margin-right: 0;
        }
        .icon {
            display: block;
            width: 51px;
            height: auto;
        }

        p {
            margin-top: 30px;
            color: #1C1C1C;
        }

        span {
            display: block;
            margin-top: 25px;
            color: #666666;
            line-height: 26px;
        }

        .car {
            width: 100%;
            height: auto;
        }
    }
}
</style>
