<!--
 * @author: JJ
 * @since: 2024-11-08
 * Tools.vue
-->
<template>
  <div class="container bg2" v-if="detail">
    <Header />
    <section>
      <swiper ref="swiper" @swiper="onSwiper" @slideChange="onSlideChange" :pagination="{ clickable: true }"
        :autoplay="{ delay: 3000, disableOnInteraction: false }" :modules="modules" class="blog_swiper product_s" loop>
        <swiper-slide v-for="(items, index) in detail.images_arr">
          <img :src="items" class="blog_banner" alt="">
        </swiper-slide>
      </swiper>

      <img src="../assets/icon/watermark_white.png" class="watermark mr0" alt="">
      <div class="w1270 mt_10 pr pb60 lh18">
        <div>
          <div class="before_link"> <router-link to="/">Home /</router-link></div>
          <div class="before_link"><router-link to="/PPF">Products /</router-link></div>
          <div class="now_link"><router-link to="/Tools">Tools</router-link></div>
        </div>
        <div class="pt65 fwb fs40 col1 lh40">Tools</div>
        <template v-for="(item, index) in resList" :key="index">
          <ul class="w1270">

            <div class="title col1 flex-box fs30 fwb">

              <div class="flex-grow-1">{{ item.name }}</div>
              <router-link class="more" :to="{ name: 'Tool', query: { type: item.id } }">
                MORE+
              </router-link>


            </div>
            <li class="item" v-for="(item1, index1) in item.goods" :key="index1">
              <router-link :to="{ name: 'ToolsInfo', query: { id: item1.id } }">
                <div class="img_nav">
                  <img :src="item1.goods_image" alt="">
                </div>
                <p class="m-ellipsis-l2 h52 medium mt20">{{ item1.goods_name }}</p>
                <p class="m-ellipsis-l2 h48 mt10 col5 fs16 lh24">{{ item1.desc }}</p>
              </router-link>

            </li>
          </ul>

        </template>

      </div>
    </section>
    <Footer />
  </div>
</template>

<script setup>
import { onBeforeMount, onMounted, ref } from "vue"
import { apiArticle, apiGetTools } from "../request/api";
import { useHead } from "@unhead/vue";
import { useRouter } from 'vue-router';
import { Navigation, Pagination, Scrollbar, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "../../node_modules/swiper/vue/swiper-vue";
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.min.css";
import 'swiper/css/effect-creative';
const modules = [Navigation, Pagination, Scrollbar, Autoplay];
const router = useRouter();

let resList = ref([]);//搜索结果
let pageNum = ref(0);//当前页数
let pageSize = ref(0);//每页有多少条数据
let total = ref(0);//一共有多少条数据

const detail = ref(null)

onMounted(() => {
  getList()
});
onBeforeMount(() => {
  getInfo()
})
const getList = async (e) => {

  try {
    const res = await apiGetTools({ page: e ? e + 1 : 1 });

    resList.value = res.data;
  } catch (e) {
    console.log(e)
  }
}
const image = ref('')
const getInfo = async () => {
  try {

    const res = await apiArticle({ title: 'Tools' }); // 调用接口


    detail.value = res.data;

    useHead({
      title: res.data.sub_title,
      meta: [
        {
          name: "description",
          content: res.data.desc,
        },
        {
          name: "keywords",
          content: res.data.keywords,
        },
      ],
    });
  } catch (error) {
    console.error(error);
  }
}

</script>
<style lang="less" scoped>
.h52 {
  font-size: 20px;
  color: #101010;
  line-height: 26px;
}
.container {
  background: #F3F7FF;
}

.blog_swiper {
  width: 100%;
  height: calc(100vw / 1440 * 400);
}

.blog_banner {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

.title {
  padding-top: 40px;
  margin-top: 0;
  width: auto;
}

.item {
  width: 400px;
  display: inline-block;
  vertical-align: top;
  background: #FFFFFF;
  box-shadow: 4px 10px 20px 0px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  padding: 30px;
  margin-right: 35px;
  margin-top: 35px;
  cursor: pointer;

  .img_nav {
    width: 100%;
    height: 230px;
    overflow: hidden;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    transition: all 0.6s;
  }

  img:hover {
    transform: scale(1.4);
  }


  &:nth-of-type(3n) {
    margin-right: 0;
  }

  &:hover p {
    color: #FB471F;
  }
}

.more {
  width: 75px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  background: #FB471F;
  border-radius: 12px;
  font-size: 14px;
  color: #FFFFFF;
  display: block;
  cursor: pointer;
}

@media screen and (max-width: 1280px) {
  .w1270 {
    max-width: 835px;
  }

  .item {
    width: 400px;
    margin-right: 35px;
    margin-top: 35px;

    img {
      width: 100%;
      height: 100%;
      display: block;
    }

    &:nth-of-type(2n) {
      margin-right: 0;
    }

    &:nth-of-type(3n) {
      margin-right: 35px;
    }

    &:nth-of-type(6n) {
      margin-right: 0;
    }
  }
}

@media screen and (max-width: 980px) {

  .item {
    width: 100%;
    margin-right: 0;
    margin-top: 35px;

    img {
      width: 100%;
      height: auto;
      display: block;
    }



    &:nth-of-type(3n) {
      margin-right: 0;
    }
  }
}
</style>
