import { get, post } from "./http";

export const apiGetIndex = (p) => get("/api/index/index", p); //首页轮播图
export const apiBaseInfomation = (p) => get("/api/index/info", p); //网站基本信息

export const apiConactInfo = (p) => get("/api/index/getConactInfo", p); //联系我们信息
export const submitConactInfo =(p) =>post('/api/index/contactUs',p)//提交信息

export const getFooter = (p) => get("/api/index/getFooter", p); // 底部联系方式

export const apiNewsList = (p) => get("/api/Blog/list", p); //Blog新闻列表
export const apiNewsInfo = (p) => get("/api/Blog/index" ,p); //Blog新闻详情
export const apiNewsDetail= (p) => get("/api/Blog/detail" ,p); //Blog新闻详情
export const apiArticle = (p) => get("/api/index/getArticles" ,p); //Blog新闻详情
export const apiGetPPF = (p) =>get('/api/index/getPPF',p)//PPF
export const apiGetAboutUs = (p) => get("/api/index/getAboutUs" ,p); //关于我们
export const apiGetCoreValues =(p) =>get('/api/index/getCoreValues',p)//CoreValues
export const apiGetPPFDetail  =(p)=>get('/api/goods/getPPFDetail',p)//ppf产品
export const apiGetTools =(p)=>get('/api/goods/getTools',p)//Tools
export const apiGetToolList  =(p)=>get('/api/goods/getToolsList',p)//Tool产品
export const apiGetToolDetail  =(p)=>get('/api/goods/getToolsDetail',p)//Tool产品详情
export const apiGetPlotterDetail =(p)=>get('/api/goods/getPlotterDetail',p)//Plotter产品详情
export const apiGetCaseDetail =(p) =>get('/api/cases/caseDetail',p)//案例详情
export const apiGetCaseList =(p) =>get('/api/cases/lists',p)//案例详情

export const ApiCategoryList = (p) => get("/api/index/categoryList", p); //分类
export const apiGetSeconds = (p) =>get('/api/index/getSecondList',p)//二级分类

export const ApiDownloadList = (p) => get("/api/index/downloadsList", p); //下载中心
export const apiFAQ =(p)=>get('/api/index/getQuestionsList',p);//问题分类


export const apiShopList = (p) => get("/api/shop/list", p); //门店列表
export const apiCountryList = (p) =>get('api/shop/getCountryList',p)//国家
export const apiCityList  = (p) =>get('api/shop/getCityList',p);
export const apiShopDetail = (p) => get("/api/shop/detail", p); //门店详情
export const apiShopInfo = (p) => get("/api/shop/info", p); //个人门店详情
export const apiShopInfos = (p) => get("/api/shop/getInfos", p); //个人信息详情
export const apiBrandGoodsDetail = (p)=>get('/api/shop/goodsDetail',p)//brand详情
export const apiBrandShops=(p)=>get('/api/shop/myGoods',p)//经销商Branding商品
export const editShopInfo = (p) => get("/api/shop/saveInfo", p); //编辑详情
export const apiGetTrainDetail =(p)=>get('/api/shop/getTrainDetail',p)//获取train详情
export const apiJoinUs=(p)=>post('api/index/joinUs',p)//

export const apiLogin = (p) => post("/api/login/login", p); //登录
export const apiForgetPwd = (p) => post("/api/login/forgetPwd", p);//忘记密码
export const apiChangePwd  = (p) => post("/api/login/changePwd", p);//修改密码
export const apigetinfos = (p) => get("/api/login/info", p); //Blog新闻列表
export const apiresetPwd =(p) =>post('/api/login/resetpwd',p)//重置密码
export const apigetLinkExpire =(p)=>get('/api/login/getLinkExpire',p)//获取链接是否失效

export  const apigetAreas =(p)=>get('/api/index/getAreas',p)
export  const  apiGetTrains=(p)=>get('/api/index/getTrains',p);

export const apiCheckWarranty=(p)=>get('/api/Warranty/checkWarranty',p)//
export const apiWarrantyList=(p)=>get('/api/Warranty/getWarrantysList',p);
export const apiWarrantyDetail=(p)=>get('/api/Warranty/getWarrantysDetail',p)
export const apigetWarrantys=(p)=>get('/api/Warranty/getWarrantys',p)

