<!--
 * @author: JJ
 * @since: 2024-11-07
 * ProductInfo.vue
-->
<template>
    <div class="container">
        <Header></Header>
        <section class="pc">
            <img :src="detail?detail.images:'../assets/icon/top.png'" class="blog_banner" alt="">
            <div class="bg ">
                <div class="w1270 pt40 pr lh18">
                    <div>
                        <div class="before_link"> <router-link to="/">Home /</router-link></div>
                        <div class="before_link"><router-link to="/PPF">Products /</router-link></div>
                        <div class="before_link"><router-link to="/PPF">PPF & Window Film </router-link> </div>
                    </div>
                    <div class="mt70 fs26 semibold col152">Product Information</div>
                    <div class="tab_nav mt50">
                        <div class="tab_item" v-for="(item, index) in tab" :key="index">
                            <div class="content flex-box flex-center">
                                <span class="tag">{{ item.brief }}</span>
                                <p class=" right fwb"><span v-html="item.name"></span></p>
                            </div>
                            <template v-if="item.goods.length > 0">
                                <ul class="list">
                                    <li v-for="(items, indexs) in item.goods" :key="indexs"><router-link
                                            :to="{ name: 'ProductInfo', query: { id: items.id } }">{{
                                            items.goods_name}}</router-link></li>
                                </ul>
                            </template>
                        </div>

                    </div>
                    <div class="mt70 flex-box info pb100" v-if="detail">
                        <div class="cover">
                            <img :src="detail.goods_image" alt="">
                        </div>
                        <div class="flex-grow-1 pl70 content">
                            <p class="fwb">{{ detail.goods_name }}</p>
                            <div class="fs38 fwb mt30">{{ detail.sub_name }}</div>
                            <div class="mt60 content" v-html="detail.content"></div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="video_link" v-if="detail">
                <div class="w1270">
                    <div class="video_item" v-for="(item, index) in detail.features" :key="index">
                        <div class="video_nav">
                            <video :src="item.image" @play="onPlay(item.id)" @pause="onPause(item.id)"
                                :controls="play_ids.includes(item.id)" :id="'myvideo' + item.id"></video>
                            <img src="../assets/icon/play1.png" v-if="!play_ids.includes(item.id)"
                                @click="operateVideo(item.id)" class="play" alt="">
                        </div>
                        <p class="semibold mt30 fs24">{{ item.name }}</p>
                    </div>
                </div>
            </div>
            <div class="product_nav" v-if="detail">
                <div class="w1270">
                    <i></i>
                    <div class="info_nav">
                        <div class="m-table">
                            <div class="item" v-for="(item, index) in detail.label" :key="index">
                                <p>{{ item.name }}</p>
                                <div class="mt15 fs20 fwb" v-if="item.type == 1"><span>{{ item.content }}</span></div>
                                <div class="mt15 fs20" v-if="item.type == 2">
                                    <img src="../assets/icon/star_on.png" v-for="(item1, index1) in item.content"
                                        :key="index1" alt="">
                                    <img src="../assets/icon/star.png" v-for="(item2, index2) in item.point"
                                        :key="index2" alt="">
                                </div>
                            </div>
                        </div>
                        <div class="flex mt20 col152 lh19">
                            <div>Note:</div>
                            <div class="flex-grow-1">
                                <p>1. All the above values are nominal and should not be used for specification purposes
                                </p>
                                <p>2. For more technical information, please visit www.joyvie.com.</p>
                            </div>
                        </div>
                    </div>
                    <a class="download_btn fs20 medium" :href="detail.pdf_url" target="_blank"><span>Download Datasheet</span></a>
                    <p class="pt30 fs26 col152 semibold">Product Structure</p>

                </div>
            </div>
            <div class="rich" v-html="detail?.tools_content"></div>
        </section>
        <section class="mobile">
            <img :src="detail?detail.images:'../assets/icon/top.png'" class="blog_banner" alt="">
            <div class="bg ">
                <div class="w1270 pt40 pr lh18">
                    <div>
                        <div class="before_link"> <router-link to="/">Home /</router-link></div>
                        <div class="before_link"><router-link to="/PPF">Products /</router-link></div>
                        <div class="before_link"><router-link to="/PPF">PPF & Window Film </router-link> </div>
                    </div>
                    <div class="mt70 fs26 semibold col152">Product Information</div>
                    <div class="tab_nav mt50">
                        <div class="tab_item" v-for="(item, index) in tab" :key="index">
                            <div class="content flex-box flex-center">
                                <span class="tag">{{ item.brief }}</span>
                                <p class=" right fwb"><span v-html="item.name"></span></p>
                            </div>
                            <template v-if="item.goods.length > 0">
                                <ul class="list">
                                    <li v-for="(items, indexs) in item.goods" :key="indexs"><router-link
                                            :to="{ name: 'ProductInfo', query: { id: items.id } }">{{
                                            items.goods_name}}</router-link></li>
                                </ul>
                            </template>
                        </div>


                    </div>
                    <div class="mt70 info pb50" v-if="detail">
                        <div class="cover">
                            <img :src="detail.goods_image" alt="">
                        </div>
                        <div class="content">
                            <p class="fwb">{{ detail.goods_name }}</p>
                            <div class="fs38 fwb mt30">{{ detail.sub_name }}</div>
                            <div class="mt60 content" v-html="detail.content"></div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="video_link" v-if="detail">
                <div class="w1270">
                    <div class="video_item" v-for="(item, index) in detail.features" :key="index">
                        <div class="video_nav">
                            <video :src="item.image" @play="onPlay1(item.id)" @pause="onPause1(item.id)"
                                :controls="play_ids1.includes(item.id)" :id="'myvideo1' + item.id"></video>
                            <img src="../assets/icon/play1.png" v-if="!play_ids1.includes(item.id)"
                                @click="operateVideo1(item.id)" class="play" alt="">
                        </div>
                        <p class="semibold mt30 fs24">{{ item.name }}</p>
                    </div>
                </div>
            </div>

            <div class="product_nav" v-if="detail">
                <div class="w1270">
                    <i></i>
                    <div class="info_nav">
                        <div class="m-table">
                            <div class="item" v-for="(item, index) in detail.label" :key="index">
                                <p>{{ item.name }}</p>
                                <div class="mt15 fs20 fwb" v-if="item.type == 1"><span>{{ item.content }}</span></div>
                                <div class="mt15 fs20" v-if="item.type == 2">
                                    <img src="../assets/icon/star_on.png" v-for="(item1, index1) in item.content"
                                        :key="index1" alt="">
                                    <img src="../assets/icon/star.png" v-for="(item2, index2) in item.point"
                                        :key="index2" alt="">
                                </div>
                            </div>
                        </div>
                        <div class="flex mt20 col152 lh19">
                            <div>Note:</div>
                            <div class="flex-grow-1">
                                <p>1. All the above values are nominal and should not be used for specification purposes
                                </p>
                                <p>2. For more technical information, please visit www.joyvie.com.</p>
                            </div>
                        </div>
                    </div>
                    <a class="download_btn medium  fs20" :href="detail.pdf_url"
                        target="_blank"><span>Download Datasheet</span></a>
                    <p class="pt30 fs26 col152 semibold">Product Structure</p>

                </div>
            </div>
            <div class="rich" v-html="detail?detail.tools_content:''"></div>
        </section>
        <Footer></Footer>
    </div>
</template>

<script setup>
import { onBeforeMount, ref } from "vue"
import { apiGetPPF, apiGetPPFDetail, apiNewsDetail } from "../request/api";
import Video from 'video.js';
import { useHead } from "@unhead/vue";
import { onBeforeRouteUpdate, useRouter } from 'vue-router';
const router = useRouter();
import { Navigation, Pagination, Scrollbar, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "../../node_modules/swiper/vue/swiper-vue";
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.min.css";
const modules = [Navigation, Pagination, Scrollbar, Autoplay];
let nindex = ref(-1)
const tab = ref([])

const itemRefs = ref([]);
const setItemRef = (el, index) => {
    if (el) {
        itemRefs.value[index] = el;
    }
    console.log(itemRefs)
};
onBeforeRouteUpdate((to) => {
    if (to.query.id) {
        id.value = to.query.id;
    }
    getInfos()
    getInfo()
});

const play_ids = ref([]);
const operateVideo = (id) => {

    if (play_ids.value.includes(id)) {

    } else {
        play_ids.value.push(id);
        var video = document.getElementById('myvideo' + id);

        video.play()
    }


}

const onPlay = (id) => {
    if (play_ids.value.includes(id)) {
        //multiAnswer.value.pop(id);
    } else {
        play_ids.value.push(id);
        var video = document.getElementById('myvideo' + id);

        video.play()
    }

};

const onPause = (id) => {
    play_ids.value.pop(id);
    var video = document.getElementById('myvideo' + id);

    video.pause()
};

const play_ids1 = ref([]);
const operateVideo1 = (id) => {

    if (play_ids1.value.includes(id)) {

    } else {
        play_ids1.value.push(id);
        var video = document.getElementById('myvideo1' + id);

        video.play()
    }


}

const onPlay1 = (id) => {
    if (play_ids1.value.includes(id)) {
        //multiAnswer.value.pop(id);
    } else {
        play_ids1.value.push(id);
        var video = document.getElementById('myvideo1' + id);

        video.play()
    }

};

const onPause1 = (id) => {

    play_ids1.value.pop(id);
    var video = document.getElementById('myvideo1' + id);

    video.pause()
};

const params = router.currentRoute.value.query;
const query = router.currentRoute.value;

const id = ref(0);
if (params.id) {
    id.value = params.id;
}
const detail = ref(null);

onBeforeMount(() => {


    getInfos()
    getInfo();
})

const getInfos = async () => {
    try {

        const res = await apiGetPPFDetail({ id: id.value }); // 调用接口


        if (res.code != 1) {
            return;
        }
        if (res.data) {


            detail.value = res.data;
        }

        useHead({
            title: res.data.sub_title,
            meta: [
                {
                    name: "description",
                    content: res.data.desc,
                },
                {
                    name: "keywords",
                    content: res.data.keywords,
                },
            ],
        });
    } catch (error) {
        console.error(error);
    }
}
const showcategorys = ref(null);

const getInfo = async () => {
    try {


        const res = await apiGetPPF({}); // 调用接口
        if (res.code != 1) {
            return
        }
        if (res.data.categorys) {
            if (res.data.categorys.length > 0) {
                showcategorys.value = res.data.categorys[0];
            }
            tab.value = res.data.categorys;
        }



    } catch (error) {
        console.error(error);
    }
}

const changeindex = async (index) => {
    showcategorys.value = tab.value[index];
}
</script>
<style lang="less" scoped>
.pc {
    display: block;
}

.mobile {
    display: none;
}

.container {
    background-color: #ECEEEE;
}

.blog_banner {
    width: 100%;
    height: calc(100vw / 1440 * 313);
    object-fit: cover;
}

.before_link {

    a {
        color: #152F4A;
    }
}

.before_link:hover a {
    color: #FB471F;
}

.w1100 {
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;
}

.bg {
    width: 100%;
    background-image: url('../assets/icon/bg.png');
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: repeat-y;
}

.download_btn {
    width: 255px;
    line-height: 64px;
    background: #152E4A;
    border-radius: 13px;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    color: #FFFFFF;
    text-align: center;
    display: block;

    span::after {
        width: 12px;
        height: 12px;
        background-image: url('../assets/icon/download.png');
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: cover;
        content: '';
        display: inline-block;
        vertical-align: middle;
    }
}

.tab_nav {
    padding-bottom: 100px;
    .tab_item {
        display: inline-block;
        vertical-align: top;
        margin-right: 10px;
        width: 175px;
        background: #C0C6C8;
        border-radius: 36px;
        cursor: pointer;
        position: relative;

        &:nth-of-type(6n) {
            margin-right: 0;
        }

        .list {
            display: none;
        }

        .content {
            width: 175px;
            height: 73px;
            position: relative;
            z-index: 2;

            img {

                width: 31px;
                height: 31px;
                object-fit: cover;
                display: block;
            }

            .right {
                margin-left: 10px;
                padding-left: 10px;
                border-left: 1px solid #152F4A;
                height: 29px;
                line-height: 17px;
                color: #152F4A;
            }
        }

        &:hover {
            background: #FFFFFF;

        }

        &:hover .list {
            display: block;
            padding-left: 35px;
            padding-right: 20px;
            padding-bottom: 25px;
            padding-top: 5px;
            position: absolute;
            top: 73px;
            left: 0;
            right: 0;
            background: #FFFFFF;
            border-radius: 0 0 36px 36px;
        }

        &:hover .list::before {
            content: '';
            background: #FFFFFF;
            top: -36px;
            width: 100%;
            left: 0;
            height: 36px;
            position: absolute;
            z-index: 1;
        }

        li {

            position: relative;

            a {
                display: inline-block;
                border-bottom: 1px solid #152F4A;
                line-height: 22px;
                padding-right: 20px;
                vertical-align: middle;
                cursor: pointer;
            }
        }

        li:before {
            content: '';
            display: inline-block;
            vertical-align: middle;
            margin-right: 10px;
            width: 3px;
            height: 3px;
            background: #152F4A;
            border-radius: 2px;

        }

        .tag {
            width: 31px;
            display: block;
            line-height: 31px;
            text-align: center;
            font-size: 25px;
            color: #FFFFFF;
            background: #152F4A;
            border-radius: 50%;
            font-family: 'Metropolis-SemiBold';
        }
    }
}

.cover {
    width: 504px;
    height: 582px;
    border-radius: 23px;
    overflow: hidden;

    img {
        width: 100%;
        height: 100%;
        display: block;
        border-radius: 23px;
        object-fit: cover;
        transition: all 0.6s;
    }

    img:hover {
        transform: scale(1.4);
    }
}

.info {
    p {
        font-size: 82px;
        color: #152F4A;
        line-height: 90px;
    }

    .content {

        font-weight: 400;
        font-size: 22px;
        color: #152F4A;
        line-height: 40px;
    }
}

.pb100 {
    padding-bottom: 145px;
}

.video_link {
    border-radius: 8px 8px 0 0;
    position: relative;
    width: 100%;
    background: #fff;
    padding-top: 112px;
    padding-bottom: 140px;

    &::after {
        content: '';
        width: 460px;
        height: 38px;
        background-image: url('../assets/icon/warranty_border.png');
        background-position: 50% 50%;
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
        display: block;
    }

    &::before {
        content: '';
        width: 423px;
        height: 105px;
        background-image: url('../assets/icon/video_btn.png');
        background-position: 50% 50%;
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        top: -69px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 2;
    }

    .video_item {
        width: calc((100% - 35px) / 2);
        display: inline-block;
        vertical-align: top;
        margin-right: 35px;
        margin-top: 60px;

        &:nth-of-type(2n) {
            margin-right: 0;
        }

        &:nth-of-type(-n+2) {
            margin-top: 0;
        }

    }

    .video_nav {
        width: 100%;
        height: calc(1280 / 532 * 277);
        display: block;

        position: relative;

        video {
            width: 100%;
            height: 100%;
            display: block;
            object-fit: cover;
            border-radius: 8px;
            position: relative;
            z-index: 1;
        }

        .play {
            width: 60px;
            height: 60px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            cursor: pointer;
            z-index: 2;
        }
    }

}

.product_nav {
    background-color: #ECEEEE;
    width: 100%;
    background-image: url('../assets/icon/bottom_bg.png');
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 100% calc(100vw / 1920 * 680);
    padding-top: 130px;
    padding-bottom: 40px;

    i {
        width: 554px;
        height: 57px;
        background-image: url('../assets/icon/title.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 50% 50%;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .info_nav {
        width: 100%;
        height: auto;
        background: #FFFFFF;
        box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.11);
        border-radius: 30px;
        margin-top: 75px;
        padding: 75px 78px 50px;

        .item {
            padding: 20px 0 0;
            height: 100px;
            width: 25%;
            display: inline-block;
            vertical-align: top;
            position: relative;
            color: #1F324A;

            &::after {
                content: '';
                width: 220px;
                height: 1px;
                border-top: 1px dashed #DFDDDB;
                display: block;
                position: absolute;
                top: 0;
                left: 0;
            }

            &:nth-of-type(-n+4)::after {
                display: none;
            }

            p {
                font-size: 12px;
                color: #FB471F;
            }

            img {
                display: inline-block;
                width: 28px;
                height: 32px;
                object-fit: cover;
            }

            img+img {
                margin-left: 5px;
            }
        }

    }

    .img1 {
        width: 755px;
        height: auto;
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 75px;
    }
}

.mt50 {
    margin-top: 50px;
}

.lh19 {
    line-height: 19px;
}

.bottom_img {
    width: 100%;
    height: auto;
    display: block;
    position: relative;
    margin-top: -233px;
}





@media screen and (max-width: 1280px) {
    .tab_nav {

        .tab_item {
            display: inline-block;
            vertical-align: top;
            margin-right: 8px;
            width: 150px;
            background: #C0C6C8;
            border-radius: 36px;
            cursor: pointer;
            position: relative;

            &:nth-of-type(6n) {
                margin-right: 0;
            }

            .list {
                display: none;
            }

            .content {
                width: 150px;
                height: 73px;
                position: relative;
                z-index: 2;

                img {

                    width: 31px;
                    height: 31px;
                    object-fit: cover;
                    display: block;
                }

                .right {
                    margin-left: 10px;
                    padding-left: 10px;
                    border-left: 1px solid #152F4A;
                    height: 29px;
                    line-height: 17px;
                    color: #152F4A;
                }
            }

            &:hover {
                background: #FFFFFF;

            }

            &:hover .list {
                display: block;
                padding-left: 35px;
                padding-right: 20px;
                padding-bottom: 25px;
                padding-top: 5px;
                position: absolute;
                top: 73px;
                left: 0;
                right: 0;
                background: #FFFFFF;
                border-radius: 0 0 36px 36px;
            }

            &:hover .list::before {
                content: '';
                background: #FFFFFF;
                top: -36px;
                width: 100%;
                left: 0;
                height: 36px;
                position: absolute;
                z-index: 1;
            }

            li {

                position: relative;

                a {
                    display: inline-block;
                    border-bottom: 1px solid #152F4A;
                    line-height: 22px;
                    padding-right: 20px;
                    vertical-align: middle;
                    cursor: pointer;
                }
            }

            li:before {
                content: '';
                display: inline-block;
                vertical-align: middle;
                margin-right: 10px;
                width: 3px;
                height: 3px;
                background: #152F4A;
                border-radius: 2px;

            }

            .tag {
                width: 31px;
                display: block;
                line-height: 31px;
                text-align: center;
                font-size: 25px;
                color: #FFFFFF;
                background: #152F4A;
                border-radius: 50%;
                font-family: 'Metropolis-SemiBold';
            }
        }
    }
}


@media screen and (max-width: 980px) {
    .pt40 {
        padding-top: 20px;
    }

    .mt70 {
        margin-top: 45px;
    }

    .mt50 {
        margin-top: 25px;
    }

    .pc {
        display: none;
    }

    .mobile {
        display: block;
    }

    ::-webkit-scrollbar {
        display: none;
    }

    .tab_nav {
        white-space: nowrap;
        overflow-x: auto;
        position: relative;
        overflow-y: visible;
        height: 180px;

        .tab_item {
            display: inline-block;
            vertical-align: top;
            margin-right: 8px;
            width: 150px;
            background: #C0C6C8;
            border-radius: 36px;
            cursor: pointer;
            position: relative;

            &:nth-of-type(6n) {
                margin-right: 0;
            }

            .list {
                display: none;
            }

            .content {
                width: 150px;
                height: 73px;
                position: relative;
                z-index: 2;

                img {

                    width: 31px;
                    height: 31px;
                    object-fit: cover;
                    display: block;
                }

                .right {
                    margin-left: 10px;
                    padding-left: 10px;
                    border-left: 1px solid #152F4A;
                    height: 29px;
                    line-height: 17px;
                    color: #152F4A;
                }
            }

            &:hover {
                background: #FFFFFF;

            }

            &:hover .list {
                display: block;
                padding-left: 35px;
                padding-right: 20px;
                padding-bottom: 25px;
                padding-top: 5px;
                position: absolute;
                top: 73px;
                left: 0;
                right: 0;
                background: #FFFFFF;
                border-radius: 0 0 36px 36px;
            }

            &:hover .list::before {
                content: '';
                background: #FFFFFF;
                top: -36px;
                width: 100%;
                left: 0;
                height: 36px;
                position: absolute;
                z-index: 1;
            }

        }
    }

    .cover {
        width: 100%;
        height: calc(100% / 504 * 582);
    }

    .info {
        p {
            font-size: 41px;
            color: #152F4A;
            line-height: 45px;
        }

        .content {
            margin-top: 30px;
            font-size: 14px;
            line-height: 20px;
        }
    }

    .video_link {

        width: 100%;

        padding-top: 40px;
        padding-bottom: 50px;
        margin-top: 30px;

        &::after {
            width: calc(460px * 0.6);
            height: calc(38px * 0.6);
        }

        &::before {
            width: calc(423px * 0.6);
            height: calc(105px * 0.6);
            top: -41px;
        }

        img {
            width: 100%;
        }

        .video_item {
            width: 100%;
            display: block;
            margin-right: 0;
            margin-top: 15px;

            &:nth-of-type(2n) {
                margin-right: 0;
            }

            &:nth-of-type(-n+2) {
                margin-top: 15px;
            }

        }

        .video_nav {
            width: 100%;
            height: calc(1280 / 532 * 277);
            display: block;
        }
    }

    .product_nav {

        padding-top: 65px;
        padding-bottom: 30px;

        i {
            width: calc(554px * 0.6);
            height: calc(57px * 0.6);
        }

        .info_nav {
            width: 100%;
            margin-top: 30px;
            padding: 15px;

            .item {
                height: 100px;
                width: calc(50% - 5px);


                margin-right: 10px;

                &:nth-of-type(2n) {
                    margin-right: 0;
                }

                &::before {
                    content: '';
                    width: 100%;
                    height: 1px;
                    border-bottom: 1px dashed #DFDDDB;
                    border-top: none;
                    display: block;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                }

                &::after {

                    display: none;
                }

                p {
                    font-size: 11px;

                }

                img {

                    width: 25px;
                    height: 28px;

                }
            }

        }
    }

    .bottom_img {
        margin-top: -50px;
    }

    .download_btn {
        width: 190px;
        line-height: 64px;
        background: #152E4A;
        border-radius: 13px;
        margin-top: 30px;
        margin-left: auto;
        margin-right: auto;
        color: #FFFFFF;
        text-align: center;
        display: block;

        span::after {
            width: 12px;
            height: 12px;
            background-image: url('../assets/icon/download.png');
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-size: cover;
            content: '';
            display: inline-block;
            vertical-align: middle;
        }
    }
}
</style>
<style>
.rich img {
    max-width: 100%;
    width: 100%;
    height: auto;
    display: block;
}
</style>