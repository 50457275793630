<!--
 * @author: JJ
 * @since: 2024-11-08
 * software.vue
-->
<template>
  <div class="container" v-if="detail">
    <Header />
    <section class="main">
<!--      <div class="blog_banner flex-box flex-center" >-->
<!--        <img :src="detail.image" class="title" alt="">-->
<!--        <img :src="detail.image?detail.image:'../assets/icon/top.png'" class="blog_banner" alt="">-->
<!--      </div>-->
      <img :src="detail.image?detail.image:'../assets/icon/top.png'" class="blog_banner" alt="">
      <div class="bg">
        <div class="w1270 pt40 pr lh18">
          <div>
            <div class="before_link"> <router-link to="/">Home /</router-link></div>
            <div class="before_link"><router-link to="/WarrantyCheck">Technical Center /</router-link></div>
            <div class="before_link"><router-link to="/Training">Training</router-link> </div>
          </div>
          <div class="mt70 fs26 semibold col152">Training</div>
          <section class="pc">
            <div class="ptb80 flex-box ">
              <div class="flex-grow-1 pr90">
                <!-- <img :src="detail.mini_image" class="title1" alt=""> -->
                <p class="fs70 col152 title1 lh80 fwb">{{detail.mini_image}}</p>
                <div class="mt60 fs17 lh22 col152">{{ detail.content }}</div>
              </div>
              <div class="video_nav">
                <video @play="onPlay" :src="detail.images" @pause="onPause" :controls="controls" ref="myVideo1"></video>
                <img src="../assets/icon/play1.png" v-if="!controls" @click="operateVideo" class="play" alt="">
              </div>
            </div>
          </section>
          <section class="mobile">
            <div class="pt40 pb20">
              <div class="video_nav">
                <video @play="onPlay" :src="detail.images" @pause="onPause" :controls="controls" ref="myVideo"></video>
                <img src="../assets/icon/play1.png" v-if="!controls" @click="operateVideo1" class="play" alt="">
              </div>
              <div class="mt30">
                <!-- <img :src="detail.mini_image" class="title1" alt=""> -->
                <p class="fs36 col152 title1 lh42 fwb">{{detail.mini_image}}</p>
                <div class="mt60 fs17 lh22 col152">{{ detail.content }}</div>
              </div>
            </div>
          </section>
        </div>
        <section class="pc">
          <div class="bg2 ">
            <div class="w1270  flex pt130 col152">
              <div class="flex-grow-1 pr80">
                <h2 class="mid_title fwb">Experienced Trainers</h2>
                <div class="mt30">{{ detail.experienced_trainers }}
                </div>
              </div>
              <div class="flex-grow-1 pr80">
                <h2 class="mid_title fwb">Professional and <br /> well-structured courses.</h2>
                <div class="mt30">{{ detail.professional }}
                </div>
              </div>
              <div class="flex-grow-1">
                <h2 class="mid_title fwb">Authoritative certification, continuous improvement.</h2>
                <div class="mt30">{{ detail.authoritative }}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="mobile">
          <div class="bg2">
            <div class="w1270  pt130 col152">
              <div>
                <h2 class="mid_title fwb">Experienced Trainers</h2>
                <div class="mt30">{{ detail.experienced_trainers }}</div>
              </div>
              <div class="mt50">
                <h2 class="mid_title fwb">Professional and <br /> well-structured courses.</h2>
                <div class="mt30">{{ detail.professional }}
                </div>
              </div>
              <div class="mt50">
                <h2 class="mid_title fwb">Authoritative certification, continuous improvement.</h2>
                <div class="mt30">{{ detail.authoritative }}</div>
              </div>
            </div>
          </div>
        </section>
        <div class="swiper_link">
          <div class="w1270 flex-box">
            <div class="flex-grow-1 pr80">
              <h1 class="fs56 fwb col152">MEET<br />OUR TRAINERS</h1>
              <div class="fs17 lh22 col152 mt35">{{ detail.trainers_content }}</div>
            </div>
            <swiper ref="swiper" @swiper="onSwiper" @slideChange="onSlideChange" :modules="modules"
              class="training_swiper" :observer="true" :observeParents="true" :slides-per-view="isPc ? 2 : 1.2">
              <swiper-slide v-for="(items, indexs) in detail.trainers" :key="indexs"
                class="training_swiper_item m-shadow" :class="[activeIndex == indexs ? 'active' : '']">
                <img :src="items.image" class="cover" alt="">
                <div class="ptb10 plr30 col152">
                  <p class="fs22 fwb">{{ items.name }}</p>
                  <div class="mt10 fs12 m-ellipsis-l9 h6">{{ items.desc }}</div>
                  <div class="train_btn" @click="showpops(indexs)">View Profile</div>
                </div>
              </swiper-slide>
            </swiper>
          </div>
        </div>
        <div class="evi_link">
          <div class="w1270">
            <div class="fs17 lh24 col152 tc">{{ detail.name }}</div>
          </div>
        </div>
        <div class="image_show w1270">
          <img :src="item" v-for="(item, index) in detail.resource_images" alt="">

        </div>
        <h1 class="tc fs56 col152 mt80 fweb w1270">Professional Tool Sets</h1>
        <h2 class="mt10 col152 fs17 lh23 tc w1270">{{ detail.tools }}</h2>
        <div class="training_mid_div pr">
          <swiper ref="swiper1" @swiper="onSwiper1" @slideChange="onSlideChange1" :modules="modules" :autoplay="{
            delay: 0, stopOnLastSlide: false, disableOnInteraction: false
          }" class="training_mid" :speed="5000" loop :spaceBetween="30" :observer="true" :observeParents="true"
            :slides-per-view="isPc ? 3.5 : 2" loopedSlides="6">
            <swiper-slide v-for="(item, index) in detail.tools_images" :key="index">
              <img :src="item" alt="">
            </swiper-slide>
          </swiper>
        </div>
        <h1 class="fs56 col152 fweb mt10 tc">BOOK YOUR TRAINING</h1>
        <div class="train_ul">
          <ul class="w930 mt60 ">
            <li class="train_item" v-for="(item, index) in detail.trains" :key="index">
              <img :src="item.image" class="cover" alt="">
              <p class="tips fs17 fwb">For Everyone</p>
              <div class="p20 col152">
                <div class="fweb fs17 lh18 m-ellipsis-l2" v-html="item.name"></div>
                <div class="m-ellipsis-l4 fs12 medium lh14 mt10">{{ item.desc }}</div>
                <div class="mt20 bb">
                  <div class="flex-box flex-between">
                    <div class="fs12 fwb lh12">Certification<br />Awarded</div>
                    <el-select v-model="item.nation_id" @change="getAreas" placeholder="Available region"
                      class="custom-select m-select">
                      <el-option v-for="items in item.nations" :key="items.id" :label="items.name" :value="items" />
                    </el-select>
                  </div>
                  <p class="lh14 mt10 h14" v-for="(item1, index1) in item.addresslist">{{ item1.address }}</p>
                </div>
                <div class="flex-box mt10">
                  <div class="flex-grow-1 fweb fs21">{{ item.cost_days }} days</div>
                  <router-link :to="{ name: 'TrainingInfo', query: { id: item.id } }" class="read_more fwb tc">READ
                    MORE</router-link>
                </div>
              </div>
            </li>
          </ul>
        </div>

        <div class="popup-mask" :class="showpop ? 'popup-mask_visible' : ''" @click="showpop = false"></div>
        <div class="popup-wrap" :class="showpop ? 'popup-wrap_visible' : ''">
          <div class="popup">
            <img src="../assets/icon/new_close.png" class="close" alt="" @click="showpop = false">
            <div class="flex flex-align-end" v-if="trainers">
              <img :src="trainers.big_image" class="sy" alt="">
              <div class="right col152">
                <p class="fweb fs45">{{ trainers.name }}</p>
                <p class="mt60 fs20 medium">SPECIALIZATION</p>
                <p class="fs15">{{ trainers.special }}</p>
                <p class="sq"></p>
                <div class="rich_right" v-html="trainers.content"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer />
  </div>
</template>

<script setup>
import { onBeforeMount, onMounted, ref, watch } from "vue"
import { apiArticle, apiCityList, apigetAreas, apiGetToolDetail } from "../request/api";
import { useHead } from "@unhead/vue";
import { Navigation, Pagination, Scrollbar, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "../../node_modules/swiper/vue/swiper-vue";
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.min.css";
const modules = [Navigation, Pagination, Scrollbar, Autoplay];
let swi = ref(null)
let activeIndex = ref(0)
let showpop = ref(false);
let trainers = ref(null);
//const value = ref('Ease of Use: The software is user-friendly, making it easy for installers to operate and learn.\n\nHigh Precision: With accurate data and cutting capabilities, Joycut enhances installation efficiency and material savings, ensuring the film adheres perfectly to the vehicle’s surface.\n\nGlobal Reach: Joycut is used in over 90 countries, meeting the market’s demands for precise, fast-cutting solutions.\n\nImproved Efficiency: Joycut helps significantly improve installation speed and precision, making it a perfect assistant for professional film installers')

const detail = ref(null);
const myVideo1 = ref(null);
const myVideo = ref(null);
const controls = ref(false);
onBeforeMount(() => {
  getInfos()
})
const operateVideo = () => {
  if (myVideo1.value) {

    if (controls.value) {
      //
      myVideo1.value.pause();
      controls.value = false;
    } else {
      myVideo1.value.play();

      controls.value = true;
    }
  }

}
const operateVideo1 = () => {
  if (myVideo.value) {

    if (controls.value) {
      //
      myVideo.value.pause();
      controls.value = false;
    } else {
      myVideo.value.play();

      controls.value = true;
    }
  }

}
const onPlay = () => {
  controls.value = true;
  console.log('Video is playing');
};

const onPause = () => {
  controls.value = false;
  console.log('Video is paused');
};
const showpops = (index) => {
  console.log(index)
  trainers.value = detail.value.trainers[index];
  console.log(detail.value);
  showpop.value = true;
}
const getInfos = async () => {
  try {

    const res = await apiArticle({ title: 'Training' }); // 调用接口

    detail.value = res.data;
    console.log(detail.value)


    useHead({
      title: res.data.sub_title,
      meta: [
        {
          name: "description",
          content: res.data.desc,
        },
        {
          name: "keywords",
          content: res.data.keywords,
        },
      ],
    });
  } catch (error) {
    console.error(error);
  }
}
const onSwiper = (e) => {
  swi.value = e
  console.log(swi.value);

}
const onSlideChange = (swiper) => {
  activeIndex.value = swiper.realIndex
};
const screenWidth = ref(window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth)
let isPc = ref(true)
onMounted(() => {
  if (screenWidth.value > 980) {
    isPc.value = true;
  } else {
    isPc.value = false;
  }
  window.onresize = () => {
    return (() => {
      screenWidth.value = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

      if (screenWidth.value > 980) {
        isPc.value = true;
      } else {
        isPc.value = false;
      }
      console.log(isPc.value);

    })()
  }


})
watch(() => screenWidth, (val) => {
  screenWidth.value = val

})


const getAreas = async (e) => {

  console.log(e);

  const res = await apigetAreas({ train_id: e.train_id, country_id: e.id }); // 调用接口
  if (res.code != 1) {
    detail.value.trains[e.index].addresslist = [];
  } else {
    detail.value.trains[e.index].addresslist = res.data;
  }
}
</script>
<style lang="less" scoped>
.h14 {
  height: 14px;
}

.container {
  background-color: #ECEEEE;
}

.blog_banner {
  width: 100%;
  height: calc(100vw / 1440 * 313);
  object-fit: cover;

  .title {
    width: 45.8%;
    height: auto;
    display: block;

  }
}

.before_link {

  a {
    color: #152F4A;
  }
}

.before_link:hover a {
  color: #FB471F;
}

.pc {
  height: auto;
  overflow: auto;
}

.mobile {
  height: 0;
  overflow: hidden;
}

.blog_swiper {
  width: 100%;
  height: calc(100vw / 1440 * 400);
}

.pr90 {
  margin-right: 90px;
}

.title1 {
  width: 515px;
  display: block;
}

.video_nav {
  position: relative;
  width: 395px;
  height: 470px;
  border-radius: 23px;

  video {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
    border-radius: 23px;
  }

  .play {
    width: 72px;
    height: 72px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    cursor: pointer;
    z-index: 2;
  }
}

.bg {
  width: 100%;
  background-image: url('../assets/icon/training_bg.png');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: repeat-y;
}

.bg2 {
  width: 100%;
  height: calc(100vw / 1440 * 380);
  background-image: url('../assets/icon/training_bg1.png');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: transparent;
  position: relative;
  z-index: 2;
}

.pt130 {
  padding-top: 130px;
}

.mid_title {
  height: 46px;
  line-height: 23px;
  font-size: 23px;
  display: table-cell;
  vertical-align: bottom;
}

.pr80 {
  padding-right: 80px;
}

.swiper_link {
  width: 100%;
  position: relative;
  padding-top: 150px;
  padding-bottom: 150px;

  &::before {
    content: '';
    display: block;
    width: 800px;
    height: 450px;
    background-image: url('../assets/icon/sq.png');
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    right: 0;
    top: -225px;
    z-index: 0;
  }
}

.fs56 {
  font-size: 56px;
}

.training_swiper {
  width: 525px;
  height: 450px;

  &_item {
    width: 250px !important;
    height: 432px;
    margin-right: 25px;
    background: #fff;
    // transform: scale(0.9);
    transform: scale(1);
    cursor: pointer;
  }

  &_item.active {
    transform: scale(1);
  }

  .cover {
    width: 100%;
    height: 205px;
    object-fit: cover;
  }

  .train_btn {
    font-size: 15px;
    color: #fff;
    border-radius: 12px;
    width: 170px;
    line-height: 24px;
    background: #EB5525;
    display: block;
    margin: 10px auto 0;
    text-align: center;
  }

  .h6 {
    line-height: 13px;
    height: 117px;
  }
}

.evi_link {
  border-radius: 8px 8px 0 0;
  position: relative;
  width: 100%;
  background: #fff;
  padding-top: 112px;
  padding-bottom: 140px;

  &::after {
    content: '';
    width: 460px;
    height: 38px;
    background-image: url('../assets/icon/warranty_border.png');
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    display: block;
  }

  &::before {
    content: '';
    width: 423px;
    height: 105px;
    background-image: url('../assets/icon/Environment.png');
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    top: -69px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
  }

}

.image_show {
  margin-top: -90px;
  white-space: nowrap;
  position: relative;
  z-index: 3;
  text-align: center;

  img {
    width: 280px;
    height: 190px;
    border-radius: 23px;
    margin-right: 20px;
  }
}

.training_mid {
  width: 100%;
  height: 188px;
  margin-top: 75px;

  img {
    width: 390px;
    height: 188px;
    object-fit: cover;
    display: block;
    border-radius: 89px;
  }
}

.training_mid_div {
  padding-bottom: 95px;

  &::after {
    content: '';
    display: block;
    width: 650px;
    height: 190px;
    background-image: url('../assets/icon/sq_l.png');
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 0;
  }
}

.train_ul {
  padding-bottom: 60px;
  position: relative;

  &::before {
    content: '';
    display: block;
    width: 540px;
    height: 330px;
    background-image: url('../assets/icon/mini.png');
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    right: 0;
    bottom: 60px;
    z-index: 0;
  }

  .train_item {
    width: 290px;
    height: 440px;
    border-radius: 17px;
    background: #fff;
    box-shadow: 3px 3px 7px 1px rgba(0, 0, 0, .3);
    position: relative;
    margin-right: 30px;
    margin-top: 45px;
    display: inline-block;
    vertical-align: top;
    &:nth-of-type(3n) {
      margin-right: 0;
    }

    .cover {
      width: 100%;
      height: 160px;
      object-fit: cover;
      border-radius: 17px 17px 0 0;
      display: block;
    }

    .tips {
      position: relative;
      width: 143px;
      height: 36px;
      line-height: 36px;
      display: block;
      background: #FD4825;
      margin: -18px auto 0;
      text-align: center;
      color: #fff;
      border-radius: 18px;
    }

    .bb {
      padding-bottom: 15px;
      border-bottom: 1px solid #152F4A;
    }

    .read_more {
      display: block;
      font-size: 12px;
      width: 84px;
      height: 20px;
      line-height: 20px;
      text-align: center;
      border-radius: 10px;
      border: 1px solid #152F4A;
    }
  }
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 8px;
  border: 1px solid #999;
  border-radius: 5px;
  background-color: #f1f1f1;
  color: #333;
  font-size: 16px;
  width: 200px;
}

/* 美化select的下拉箭头 */
select:after {
  content: "\25BC";
  /* Unicode编码，表示向下的箭头 */
  position: absolute;
  top: 12px;
  right: 10px;
}

/* 美化选项内容 */
option {
  padding: 5px;
  background-color: #f9f9f9;
  color: #333;
}

/*弹窗*/
.popup-wrap {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 100;
  transition: all 0.4s ease;
  opacity: 1;
  overflow: hidden;
  width: 0;
  transform: none;
}

.popup-wrap.popup-wrap_visible {
  width: calc(100% - 30px);
  transition: all 0.4s ease;
  transform: none;
  height: calc(100vh - 90px);
  margin: 75px 15px 15px;
}

.popup-mask {
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  position: fixed;
}

.popup {
  max-width: 1440px;
  height: 766px;
  background: #fff;
  position: relative;
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: 80px;
  margin-left: auto;
  margin-right: auto;
  overflow-y: auto;

  .close {
    width: 16px;
    height: 16px;
    position: absolute;
    top: 18px;
    right: 18px;
  }

  .sy {
    width: 516px;
    height: 510px;
    border-radius: 33px;
    box-shadow: 3px 3px 7px 1px rgba(0, 0, 0, .3);
    object-fit: cover;
    display: block;
    position: relative;
    margin-right: -210px;
  }

  .right {
    width: 1055px;
    height: 666px;
    display: block;
    background: #EFECEB;
    border-radius: 108px 0 0 108px;
    padding-left: 255px;
    padding-right: 70px;
    padding-top: 100px;

    .sq {
      margin-top: 30px;
      margin-bottom: 30px;
      width: 100%;
      height: 1px;
      background: #EB5525;
    }

   /deep/ ul {
      width: 100%;
      max-height: 260px;
      overflow-y: hidden;
    }

   /deep/ li {
      &::before {
        content: '';
        width: 3px;
        height: 3px;
        border-radius: 50%;
        margin-top: 8px;
        margin-right: 8px;
        background: #152F4A;
        display: inline-block;
        vertical-align: top;
      }

      margin-top: 5px;
    }
  }
}
@media screen and (max-height: 800px) {
  .popup {
  max-width: 1440px;
  height: 100%;
}
}
@media screen and (max-width: 1280px) {
  .bg2 {
    width: 100%;
    height: calc(100vw / 1440 * 380 * 1.5);
    background-image: url('../assets/icon/training_bg1.png');
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
  }

  .fs56 {
    font-size: 52px;
  }

  .training_mid {
    width: 100%;
    height: 169px;

    img {
      width: 350px;
      height: 169px;
      object-fit: cover;
      display: block;
      border-radius: 80px;
    }
  }

  .train_ul {
    padding-bottom: 60px;

    .train_item {
      width: 290px;
      height: 440px;
      border-radius: 17px;
      background: #fff;
      box-shadow: 3px 3px 7px 1px rgba(0, 0, 0, .3);
      position: relative;
      margin-right: 30px;
      margin-top: 45px;
      display: inline-block;
    }
  }

  .popup {

    .sy {
      width: calc(516px * 0.9);
      height: calc(510px * 0.9);
      border-radius: 33px;
      box-shadow: 3px 3px 7px 1px rgba(0, 0, 0, .3);
      object-fit: cover;
      display: block;
      position: relative;
      margin-right: -210px;
    }


  }
}

@media screen and (max-width: 980px) {
  .mobile {
    height: auto;
    overflow: hidden;
  }

  .pc {
    height: 0;
    overflow: auto;
  }

  .video_nav {
    position: relative;
    width: calc(395px * 0.8);
    height: calc(470px * 0.8);
    margin-left: auto;
    margin-right: auto;
    border-radius: 23px;

    video {
      display: block;
      width: 100%;
      height: 100%;
      position: relative;
      z-index: 1;
      border-radius: 23px;
    }

    .play {
      width: 72px;
      height: 72px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      cursor: pointer;
      z-index: 2;
    }
  }

  .title1 {
    width: 90%;
    display: block;
  }

  .bg2 {
    width: 100%;
    min-height: calc(100vw / 1440 * 380);
    height: auto;
    background-image: url('../assets/icon/training_bg1.png');
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    padding-bottom: 40px;
  }

  .pt130 {
    padding-top: 60px;
  }

  .mid_title {
    height: 40px;
    line-height: 20px;
    font-size: 20px;
    display: table-cell;
    vertical-align: bottom;
  }

  .swiper_link {
    .flex-box {
      display: block;
    }

    .flex-grow-1 {
      width: 100%;
    }
  }

  .swiper_link {
    width: 100%;
    position: relative;
    padding-top: 100px;
    padding-bottom: 30px;

    &::before {
      content: '';
      display: block;
      width: calc(800px * 0.4);
      height: calc(450px * 0.4);
      background-image: url('../assets/icon/sq.png');
      background-position: 50% 50%;
      background-size: cover;
      background-repeat: no-repeat;
      position: absolute;
      right: 0;
      top: -90px;
      z-index: 0;
    }
  }

  .training_swiper {
    width: 100%;
    height: 450px;
    margin-top: 30px;

    &_item {
      width: 250px !important;
      height: 432px;
      margin-right: 15px;
      background: #fff;
      // transform: scale(0.9);
      transform: scale(1);
      cursor: pointer;
    }

    &_item.active {
      transform: scale(1);
    }

    .cover {
      width: 100%;
      height: 205px;
      object-fit: cover;
    }

    .train_btn {
      font-size: 15px;
      color: #fff;
      border-radius: 12px;
      width: 170px;
      line-height: 24px;
      background: #EB5525;
      display: block;
      margin: 10px auto 0;
      text-align: center;
    }

    .h6 {
      line-height: 13px;
      height: 117px;
    }
  }

  .image_show {
    margin-top: -38px;
    white-space: nowrap;
    position: relative;
    z-index: 3;

    img {
      width: 110px;
      height: 76px;
      border-radius: 10px;
      margin-right: 10px;
      object-fit: cover;
    }
  }

  .evi_link {

    width: 100%;

    padding-top: 40px;
    padding-bottom: 50px;
    margin-top: 30px;

    &::after {
      width: calc(460px * 0.6);
      height: calc(38px * 0.6);
    }

    &::before {
      width: calc(423px * 0.6);
      height: calc(105px * 0.6);
      top: -41px;
    }

    img {
      width: 100%;
    }
  }

  .training_mid {
    width: 100%;
    height: 113px;
    margin-top: 35px;

    img {
      width: 234px;
      height: 113px;
      object-fit: cover;
      display: block;
      border-radius: 53px;
    }
  }

  .training_mid_div {
    padding-bottom: 38px;

    &::after {
      content: '';
      display: block;
      width: calc(650px * 0.4);
      height: calc(190px * 0.4);
      background-image: url('../assets/icon/sq_l.png');
      background-position: 50% 50%;
      background-size: cover;
      background-repeat: no-repeat;
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 0;
    }
  }
}

@media screen and (max-width: 760px) {
  .train_ul {
    padding-bottom: 60px;

    &::before {
      content: '';
      display: block;
      width: calc(540px * 0.4);
      height: calc(330px * 0.4);
      background-image: url('../assets/icon/mini.png');
      background-position: 50% 50%;
      background-size: cover;
      background-repeat: no-repeat;
      position: absolute;
      right: 0;
      bottom: 60px;
      z-index: 0;
    }

    .train_item {
      width: 290px;
      height: 440px;
      border-radius: 17px;
      background: #fff;
      box-shadow: 3px 3px 7px 1px rgba(0, 0, 0, .3);
      position: relative;
      margin-right: auto;
      margin-left: auto;
      margin-top: 45px;
      display: block;

      &:nth-of-type(3n) {
        margin-right: auto;
      }
    }
  }

  .popup {
    max-width: 1440px;
    min-height: calc(100%);
    background: #fff;
    position: relative;
    padding-top: 25px;
    padding-bottom: 25px;
    padding-left: 20px;
    margin-left: auto;
    margin-right: auto;
    overflow-y: auto;

    .close {
      width: 16px;
      height: 16px;
      position: absolute;
      top: 18px;
      right: 18px;
    }

    .sy {
      width: calc(516px * 0.9);
      height: calc(510px * 0.9);
      border-radius: 33px;
      box-shadow: 3px 3px 7px 1px rgba(0, 0, 0, .3);
      object-fit: cover;
      display: block;
      position: relative;
      margin-right: -210px;
      display: none;
    }

    .right {
      width: 100%;
      height: 666px;
      display: block;
      background: #EFECEB;
      border-radius: 108px 0 0 108px;
      padding-left: 25px;
      padding-right: 25px;
      padding-top: 100px;

      .sq {
        margin-top: 30px;
        margin-bottom: 30px;
        width: 100%;
        height: 1px;
        background: #EB5525;
      }

      ul {
        max-height: 400px;
      }
    }
  }
}
</style>
<style>
.training_mid .swiper-wrapper {
  transition-timing-function: linear !important;
}
</style>
<style scoped>
.custom-select.m-select {
  width: 120px;

  display: inline-block;
  vertical-align: top;
  text-align: right;



}

::v-deep.custom-select.m-select.el-select .el-select__wrapper {
  font-size: 12px;

  box-shadow: none;
  padding: 0;
  text-align: right;
  border: 1px solid #152F4A;
  color: #152F4A;
  padding-right: 2px;
  border-radius: 12px;
}

.custom-select.m-select .el-input__inner {}

.custom-select.m-select.el-select {
  --el-select-input-font-size: 12px;
  font-family: "Metropolis-Regular";
  font-weight: normal;
  --el-text-color-placeholder: #152F4A;
  --el-text-color-regular: #152F4A;
}
</style>