<!--
 * @author: JJ
 * @since: 2024-11-08
 * ExampleInfo.vue
-->
<template>
 <div class="container" v-if="detail">
        <Header></Header>
        <section>
<!--            <div class="w1270 mt30  router" v-if="type==1">-->
<!--                <div class="before_link "><router-link  to="/" >Home /</router-link></div>-->
<!--              <div class="before_link "><router-link  to="/PPF" >Products /</router-link></div>-->
<!--              <div class="before_link "><router-link  to="/PPF" >PPF /</router-link></div>-->
<!--              <div class="now_link"><router-link :to="{name:'ExampleInfo',query:{id:detail.id,type:1}}">Case Detail</router-link></div>-->
<!--          </div>-->
<!--          <div class="w1270 mt30  router" v-if="type==2">-->
<!--            <div class="before_link "> <router-link  to="/" >Home /</router-link></div>-->
<!--            <div class="before_link "><router-link to="/PartnerSearch">Network /</router-link></div>-->
<!--            <div class="before_link "> <router-link to="/Collection">Collection</router-link></div>-->
<!--            <div class="now_link"><router-link :to="{name:'ExampleInfo',query:{id:detail.id,type:2}}">Case Detail</router-link></div>-->
<!--          </div>-->
<!--          <div class="w1270 mt30  router" v-if="type==3">-->
<!--            <div class="before_link "> <router-link  to="/" >Home /</router-link></div>-->
<!--            <div class="before_link"><router-link to="/PartnerSearch">Network /</router-link></div>-->
<!--            <div class="before_link"><router-link to="/PartnerSearch">Partner Search /</router-link></div>-->
<!--            <div class="before_link" v-if="shop_id!=0"><router-link :to="{ name: 'DealerDetails', query: { id: shop_id } }" >Dealer Details /</router-link></div>-->
<!--            <div class="before_link" v-if="detail.trains"><router-link :to="{ name: 'TrainingDetail', query: { id: t_id,shop_id:shop_id } }" >Training/</router-link></div>-->
<!--            <div class="now_link"><router-link :to="{name:'ExampleInfo',query:{id:detail.id,type:3,shop_id:shop_id,t_id:t_id}}">Case Detail</router-link></div>-->
<!--          </div>-->
          <div class="w1270 mt30  router" >
            <div class="before_link "> <router-link  to="/" >Home /</router-link></div>
            <div class="before_link "> <router-link  to="/ExampleList" >Cases /</router-link></div>
            <div class="now_link"><router-link :to="{name:'ExampleInfo',query:{id:detail.id}}">Case Detail</router-link></div>
          </div>
            <div class="w1270 pt65 pb80">
                <h1>{{detail.title}}</h1>
                <section>
                    <!-- 富文本空间 -->
                    <div class="rich_nav mt40" v-html="detail.content"></div>
                </section>
            </div>
            <div class="related_nav">
                <div class="w1270">
                    <div class="fwb fs40 col1 lh40">Related Cases</div>
                    <ul>
                        <li class="case_item" v-for="(item, index) in detail.cases" :key="index">
                            <router-link :to="{ name: 'ExampleInfo', query: { id: item.id, type: 1 } }">
                                <div class="img_nav">
                                    <img :src="item.image" alt="">
                                </div>
                                <p class="m-ellipsis-l2 fs20">{{ item.title }}</p>
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
        <Footer></Footer>
    </div>
</template>

<script setup>
import {ref , onBeforeMount,onMounted} from "vue"
const a = ref(0);
const type=ref('');

const id = ref(0);
import { useRouter ,onBeforeRouteUpdate} from 'vue-router';
import {apiGetCaseDetail} from "../request/api";
import {useHead} from "@unhead/vue";
const router = useRouter();
const detail = ref(null);
const params = router.currentRoute.value.query;
const shop_id =ref(0);
const t_id =ref(0);
if (params.type) {
  type.value = params.type;
}
if(params.shop_id){
  shop_id.value=params.shop_id;
}
if(params.t_id){
  t_id.value=params.t_id;
}
if(params.id){
  id.value=params.id;
}


onBeforeRouteUpdate((to) => {
  if(to.query.id){
    id.value=to.query.id;
  }
  getInfo()
});
onMounted(() => {
  getInfo()

})
const getInfo = async () => {
  try {

    const res = await apiGetCaseDetail({ id:id.value,
      type:type.value,
      shop_id:shop_id.value,
      t_id:t_id.value
    }); // 调用接口

    detail.value = res.data;


    useHead({
      title: res.data.sub_title,
      meta: [
        {
          name: "description",
          content: res.data.desc,
        },
        {
          name: "keywords",
          content: res.data.keywords,
        },
      ],
    });
  } catch (error) {
    console.error(error);
  }
}
</script>
<style lang="less" scoped>
.router div {
    display: inline-block;
    vertical-align: top;
}

h1 {
    font-family: Metropolis-Medium;
    font-size: 36px;
    color: #101010;
    line-height: 44px;
}

.tips {
    width: 62px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    font-size: 14px;
    color: #101010;
    background: #F0F4F7;
    border-radius: 4px;
    margin-right: 10px;
}

.rich_nav {
    img {
        width: 100%;
        height: auto;
        display: block;
    }
}

.related_nav {
    width: 100%;
    background: #F3F7FF;
    padding-top: 70px;
    padding-bottom: 80px;

    .blogs_item {
        width: 100%;
        height: 470px;
        background: #FFFFFF;
        box-shadow: 4px 10px 20px 0px rgba(0, 0, 0, 0.08);
        border-radius: 10px;
        padding: 30px;
        display: block;

        img {
            width: 100%;
            height: 230px;
            border-radius: 10px;
            object-fit: cover
        }

        .tips {
            width: 62px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            text-align: center;
            background: #F0F4F7;
            border-radius: 4px;
        }

        p {
            font-weight: 500;
            font-size: 20px;
            color: #101010;
            line-height: 26px;
            font-family: "Metropolis-Medium";
            height: 52px;
            margin-top: 20px;
        }
&:hover p{color: #FB471F}
        span {
            margin-top: 10px;
            height: 48px;
            font-weight: 400;
            font-size: 16px;
            color: #555555;
            line-height: 24px;
        }
      &:hover span{color: #FB471F}
    }
}
.case_item {
    margin-top: 50px;
    margin-right: 35px;
    display: inline-block;
    width: 400px;
    vertical-align: top;
    cursor: pointer;

    .img_nav {
        width: 100%;
        height: 243px;
        border-radius: 7px;
        overflow: hidden;
    }

    img {
        width: 100%;
        height: 100%;
        display: block;
        transition: all 0.6s;
        object-fit: cover;
        border-radius: 7px;
    }

    img:hover {
        transform: scale(1.4);
    }

    p {
        margin-top: 20px;
        font-family: Metropolis-Medium;
        color: #101010;
        line-height: 24px;
    }

    &:hover p {
        color: #FB471F;
    }
}

.case_item:nth-of-type(3n) {
    margin-right: 0;
}
@media screen and (max-width: 1280px) {
    .related_nav {
    width: 100%;
    padding-top: 70px;
    padding-bottom: 80px;
    .blogs_item {
    width: 100%;
    height: 470px;
    background: #FFFFFF;
    box-shadow: 4px 10px 20px 0px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    padding: 30px;
    display: block;
.img_nav{
    width: 100%;
      height: 230px;
      border-radius: 10px;
      overflow: hidden; 
}
    img {
      width: 100%;
      height: 230px;
      border-radius: 10px;
      object-fit: cover;
      transition: all 0.6s;
    }
    img:hover {
      transform: scale(1.4);
    }
    .tips {
      width: 62px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      text-align: center;
      background: #F0F4F7;
      border-radius: 4px;
    }

    p {
      font-weight: 500;
      font-size: 20px;
      color: #101010;
      line-height: 26px;
      font-family: "Metropolis-Medium";
      height: 52px;
      margin-top: 20px;
    }

    span {
      margin-top: 10px;
      height: 48px;
      font-weight: 400;
      font-size: 16px;
      color: #555555;
      line-height: 24px;
    }
  }
}
.case_item {
        margin-top: 50px;
        margin-right: 35px;
        display: inline-block;
        width: calc(400px * 0.7);
        vertical-align: top;

        .img_nav {
            height: calc(243px * 0.7);
        }

        img {
            width: 100%;
            height: 100%;
            display: block;
            object-fit: cover;
            border-radius: 7px;
        }
    }
}
@media screen and (max-width: 980px) {
    .related_nav {
        width: 100%;
        padding-top: 70px;
        padding-bottom: 80px;
    }

    .blogs_item {
        width: 100%;
        height: 470px;
        background: #FFFFFF;
        box-shadow: 4px 10px 20px 0px rgba(0, 0, 0, 0.08);
        border-radius: 10px;
        padding: 30px;
        display: block;

        img {
            width: 100%;
            height: 230px;
            border-radius: 10px;
            object-fit: cover
        }

        .tips {
            width: 62px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            text-align: center;
            background: #F0F4F7;
            border-radius: 4px;
        }

        p {
            font-weight: 500;
            font-size: 20px;
            color: #101010;
            line-height: 26px;
            font-family: "Metropolis-Medium";
            height: 52px;
            margin-top: 20px;
        }

        span {
            margin-top: 10px;
            height: 48px;
            font-weight: 400;
            font-size: 16px;
            color: #555555;
            line-height: 24px;
        }
    }
    .case_item {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .case_item:nth-of-type(3n) {
        margin-right: auto;
    }
}
</style>
